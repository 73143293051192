import { Theme } from "@mui/material/styles";
import { dp, margin, responsiveCSS } from "ds/materials/metrics";
import { Body, BodyProps } from "ds/materials/typography";
import React, { CSSProperties } from "react";
import styled from "styled-components";
import { CUSTOM_BLACK } from "./colors";

type BaseTypes = {
  aligned?: boolean;
  backgroundColor?: string;
  border?: string;
  borderRadius?: string;
  boxShadow?: string;
  boxSizing?: CSSProperties["boxSizing"];
  cursor?: string;
  flexBasis?: string;
  flexShrink?: number;
  flexWrap?: string;
  gap?: CSSProperties["gap"];
  height?: string;
  justify?: CSSProperties["justifyContent"];
  margin?: string;
  maxWidth?: CSSProperties["maxWidth"];
  minHeight?: string;
  minWidth?: string;
  overflow?: string;
  padding?: CSSProperties["padding"];
  rowGap?: CSSProperties["rowGap"];
  stretched?: boolean;
  visibility?: string;
  width?: CSSProperties["width"];
  zIndex?: number | string;
};

const BaseLayout = styled.div<BaseTypes>`
  display: flex;
  flex-shrink: ${(props) => props.flexShrink || "0"};
  flex-wrap: ${(props) => props.flexWrap || "initial"};
  margin: ${(props) => props.margin || "0"};
  padding: ${(props) => props.padding || "0"};
  z-index: ${(props) => props.zIndex || undefined};
  overflow: ${(props) => props.overflow || "hidden"};
  visibility: ${(props) => props.visibility || undefined};
  cursor: ${(props) => props.cursor || undefined};
  box-shadow: ${(props) => props.boxShadow || "none"};
  border: ${(props) => props.border || dp(0)};
  border-radius: ${(props) => props.borderRadius || dp(0)};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  min-height: ${(props) => props.minHeight};
  min-width: ${(props) => props.minWidth};
  max-width: ${(props) => props.maxWidth};
  background-color: ${(props) => props.backgroundColor};
  flex-basis: ${(props) => props.flexBasis || "auto"};
  gap: ${({ gap }) => gap || 0};
  row-gap: ${({ rowGap }) => rowGap};
  box-sizing: ${({ boxSizing }) => boxSizing || "content-box"};
  justify-content: ${(props) => (props.justify ? props.justify : undefined)};
  align-items: ${(props) => (props.aligned ? "center" : "stretch")};
  flex-grow: ${(props) => (props.stretched ? "1" : undefined)};
`;

export const HorizontalLayout = styled(BaseLayout)<BaseTypes>`
  flex-direction: row;
`;

export const VerticalLayout = styled(BaseLayout)<BaseTypes>`
  flex-direction: column;
`;

export const ResponsiveLayout = styled(BaseLayout)`
  flex-direction: column;
  @media print, (min-width: 600px) {
    & {
      flex-direction: row;
    }
  }
`;

export const HideOnMobile = styled.div<{
  andTablet?: boolean | null | undefined;
}>`
  ${({ andTablet }) =>
    responsiveCSS({
      mobile: [`display: none`],
      tablet: andTablet ? [`display: none`] : [],
    })}
`;

export const Divider = styled.hr<{
  height?: string;
  margin?: string;
  width?: string;
}>`
  border: ${dp(0)};
  height: ${(props) => props.height || dp(1)};
  opacity: 0.12;
  background-color: ${CUSTOM_BLACK};
  margin: ${(props) => props.margin || "0"};
  padding: 0;
  width: ${({ width }) => width};
`;

export const MultilineTextTruncation = styled(Body)<{
  lineCramp?: number;
}>`
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ lineCramp }) => lineCramp || 1};
  overflow: hidden;
  display: -webkit-box;
`;

export function LongBody({
  children,
  ...props
}: BodyProps & {
  children: React.ReactNode | null | undefined;
  style?: React.CSSProperties;
}) {
  return (
    <Body
      margin={margin(0)}
      overflow="hidden"
      textOverflow="ellipsis"
      maxWidth="100%"
      whiteSpace="nowrap"
      {...props}
    >
      {children}
    </Body>
  );
}

export const Spacer = styled.div<{
  height?: string;
  margin?: string;
  padding?: string;
}>`
  display: flex;
  ${({ height }) => (height ? `height: ${height}` : "flex: 1")};
  margin: ${({ margin }) => margin ?? 0};
  padding: ${({ padding }) => padding ?? 0};
`;

export const MarginAdjuster = styled.div<{
  display?: string;
  margin?: string | false;
}>`
  margin: ${(props) => props.margin};
  display: ${(props) => props.display};
`;

export const iconStyle = {
  fontSize: dp(20),
  margin: margin(0, 1, 0, 0),
};

export const iconHoverBorderStyle = (theme: Theme) => ({
  outline: `${dp(1)} solid ${theme.palette.primary.main}`,
  backgroundColor: "inherit",
});

export const ShowElement = styled.div<{ show: boolean }>`
  display: ${({ show }: { show: boolean }) => (show ? "inline-block" : "none")};
`;

export const HorizontalDivider = styled.div<{ color: CSSProperties["color"] }>`
  width: 100%;
  height: ${dp(1)};
  margin: ${margin(1, 0)};
  background: ${({ color }) => color};
`;
