import PrintIcon from "@mui/icons-material/Print";
import IconButton from "@mui/material/IconButton";
import { TRACK_EVENTS } from "@recare/core/consts";
import { useTranslations } from "@recare/translations";
import { RSFAB } from "ds/components/RSButton";
import Tooltip from "ds/components/Tooltip";
import { ICON_DARK } from "ds/materials/colors";
import { sizing } from "ds/materials/metrics";
import { usePrint } from "dsl/atoms/Contexts";
import { useEffect, useLayoutEffect } from "react";
import { useTracking } from "react-tracking";

export function openPrintPage(windowInstance: Window) {
  const uri = windowInstance.location.search;
  const prefix = uri.includes("?") ? "&" : "?";
  windowInstance.open(`${windowInstance.location}${prefix}print`, "_blank");
}

export const usePrintWindow = (skip?: boolean) => {
  const print = usePrint();
  const defer = (fn: Function) => setTimeout(fn, 2000);
  useEffect(() => {
    if (skip) return;
    if (print) {
      defer(() => {
        window.print();
        defer(() => window.close());
      });
    }
  }, [print, skip]);
};

export const usePreventPrintMenu = () => {
  const { trackEvent } = useTracking();

  useLayoutEffect(() => {
    const keydown = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.keyCode == 80) {
        e.preventDefault();
        trackEvent({ name: TRACK_EVENTS.PRINT_MENU });

        openPrintPage(window);
        return false;
      }
      return true;
    };
    window.addEventListener("keydown", keydown);

    return () => window.removeEventListener("keydown", keydown);
  }, []);
};

export default function PrintButton({ fab = false }: { fab?: boolean }) {
  const print = usePrint();
  const translations = useTranslations();
  const iconStyle = { width: sizing(2.5), height: sizing(2.5) };
  const { trackEvent } = useTracking();

  usePreventPrintMenu();

  if (print) return null;

  if (fab) {
    return (
      <RSFAB
        ariaLabel={translations.actions.print}
        onClick={() => {
          trackEvent({ name: TRACK_EVENTS.PRINT_BUTTON });
          openPrintPage(window);
        }}
        tooltip={translations.actions.print}
        color="white"
      >
        <PrintIcon htmlColor={ICON_DARK} style={iconStyle} />
      </RSFAB>
    );
  }

  return (
    <IconButton
      aria-label={translations.actions.print}
      size="large"
      onClick={() => {
        trackEvent({ name: TRACK_EVENTS.PRINT_BUTTON });
        openPrintPage(window);
      }}
    >
      <Tooltip title={translations.actions.print}>
        <PrintIcon htmlColor={ICON_DARK} style={iconStyle} />
      </Tooltip>
    </IconButton>
  );
}
