import StarIcon from "@mui/icons-material/Star";
import { Paper } from "@mui/material";
import {
  APP_CAREPROVIDER,
  QUERY_PROGRESS_NOT_STARTED,
  QUERY_PROGRESS_PENDING,
} from "@recare/core/consts";
import { getProviderSearchAccountName } from "@recare/core/model/accounts";
import { useGetTranslationForSearchType } from "@recare/core/model/utils/ontologies/hooks";
import { AuctionRequest, QueryProgress } from "@recare/core/types";
import {
  Form,
  composeValidation,
  convertIn,
  convertModelDefinition,
  convertOut,
  validateModel,
} from "@recare/react-forms-state";
import { useTranslations } from "@recare/translations";
import Translations from "@recare/translations/types";
import { useEnvContext } from "context/EnvContext";
import { EncryptedHint } from "ds/components/EncryptedHint";
import { InfoBanner } from "ds/components/InfoBanner";
import { RSButtonColor, RSButtonVariant } from "ds/components/RSButton";
import RSButton from "ds/components/RSButton/RSButton";
import TextAreaInputField from "ds/components/TextArea/TextAreaForm";
import { appearanceMovement } from "ds/materials/animations";
import { GREY_50 } from "ds/materials/colors";
import { VerticalLayout } from "ds/materials/layouts";
import {
  Z_INDEX_MESSENGER,
  dp,
  margin,
  padding,
  space,
} from "ds/materials/metrics";
import { useEncryption, useEncryptionKey } from "dsl/atoms/Contexts";
import MessageRecipientHint from "dsl/atoms/MessageRecipientHint";
import { TermsAndConditions } from "dsl/atoms/TermsAndConditions";
import { useFocusElement } from "dsl/hooks/useFocusElement";
import {
  BANNER_PADDING,
  BannerTextAreaBox,
  bannerActionStyle,
} from "dsl/organisms/Banners";
import {
  CSSProperties,
  MutableRefObject,
  ReactNode,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";

export type MessengerFormProps = {
  auctionRequest: AuctionRequest;
  closeForm: () => void;
  titleId: string;
};

const BANNER_TEXT_AREA_WIDTH = `calc(100% - ${space(BANNER_PADDING - 3.5)})`;

export const pageOpacity = appearanceMovement(0, 0, { speed: "0.5s" });

const FormBannerBox = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const FormAreaDiv = styled.div<{ margin?: string }>`
  display: flex;
  flex-direction: column;
  flex: 1 0 0px;
  height: 90%;
  margin: ${({ margin }) => margin || undefined};
  width: 100%;
`;

export const Scrollable = styled.div`
  display: flex;
  overflow: auto;
  flex: 1 1 auto;
`;

export const TextFieldWrapper = styled.div<{ margin?: string }>`
  margin: ${(props) => props.margin || margin(2, 2, 4, 2)};
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
`;

export const FormWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  padding: ${padding(0, 1)};
`;

const GhostButton = styled.div`
  height: ${dp(36)};
  width: ${dp(120)};
  margin: ${margin(1, 0)};
`;

export function MessengerNonModalDialog({
  ariaLabelledBy,
  children,
}: {
  ariaLabelledBy: string;
  children: ReactNode;
  fallbackRestoreRef?: MutableRefObject<HTMLDivElement | null>;
}) {
  const fallbackRestoreEl = document.getElementById("message_input");
  const focusRef = useFocusElement<HTMLDivElement>({
    setTabIndex: true,
    restoreFocus: true,
    setTabIndexFallbackRestoreRef: false,
    fallbackRestoreRef: fallbackRestoreEl,
  });

  return (
    <Paper
      aria-labelledby={ariaLabelledBy}
      aria-modal={false}
      ref={focusRef}
      role="dialog"
      sx={{
        alignItems: "center",
        background: GREY_50,
        bottom: 0,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        position: "relative",
        top: 0,
        width: "100%",
        zIndex: Z_INDEX_MESSENGER + 1,
      }}
    >
      <FormBannerBox>{children}</FormBannerBox>
    </Paper>
  );
}

export const FormArea = ({
  children,
  margin,
}: {
  children: ReactNode;
  margin?: string;
}) => (
  <Scrollable>
    <FormAreaDiv margin={margin}>{children}</FormAreaDiv>
  </Scrollable>
);

export function ConnectedButton({
  color,
  customStyle,
  disabled,
  hide = false,
  inverted = false,
  onSubmit,
  onSubmitQueryProgress = QUERY_PROGRESS_NOT_STARTED,
  submitText,
  variant,
}: {
  color?: RSButtonColor;
  customStyle?: CSSProperties;
  disabled?: boolean;
  hide?: boolean;
  inverted?: boolean;
  onSubmit: (args: ToType) => void;
  onSubmitQueryProgress: QueryProgress;
  submitText: string;
  variant: RSButtonVariant;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const translations = useTranslations();

  useEffect(() => {
    setIsLoading(onSubmitQueryProgress === QUERY_PROGRESS_PENDING);
  }, [onSubmitQueryProgress]);

  if (hide) return <GhostButton />;

  return (
    <RSButton
      color={color}
      disabled={disabled}
      id="confirm_decline"
      inverted={inverted}
      loading={onSubmitQueryProgress}
      onClick={onSubmit}
      style={{ ...bannerActionStyle, ...customStyle }}
      variant={isLoading ? "contained" : variant}
    >
      {isLoading ? translations.actions.saving : submitText}
    </RSButton>
  );
}

export function TextArea({
  elementName,
  endAdornment,
  label,
  messenger = false,
  paddingArea,
  placeholder,
  required = false,
  rows = 9,
  showError = false,
  test = false,
}: {
  elementName: string;
  endAdornment?: ReactNode;
  label?: string;
  messenger?: boolean;
  paddingArea?: string;
  placeholder?: string;
  required?: boolean;
  rows?: number;
  showError?: boolean;
  test?: boolean;
}) {
  return (
    <TextAreaInputField
      margin={messenger ? "dense" : "normal"}
      elementName={elementName}
      label={label}
      placeholder={placeholder}
      width={`calc(100% - ${space(BANNER_PADDING - 3.5)})`}
      rows={test ? 1 : rows}
      paddingArea={paddingArea}
      hideError={!showError}
      required={required}
      endAdornment={endAdornment}
    />
  );
}

export function PreferredProviderBanner({
  auctionRequest,
}: {
  auctionRequest: AuctionRequest;
}) {
  const { app } = useEnvContext();
  const getTranslationForSearchType = useGetTranslationForSearchType();
  const preferredSelectionTranslation = getTranslationForSearchType({
    translationKey: "auctionRequest.preferredSelection",
    receiverType: "receiverTypeSingularPreferred",
    searchType: auctionRequest.auction?.search_type,
  });

  if (app !== APP_CAREPROVIDER || !auctionRequest?.patient_preferred) {
    return null;
  }

  return (
    <InfoBanner
      icon={<StarIcon fontSize="inherit" />}
      message={preferredSelectionTranslation}
      severity="warning"
    />
  );
}

export const BasicForm = (definition: ToType, validation?: ToType) => {
  const modelDefinition = convertModelDefinition(definition);

  const validateForm =
    validation ||
    function validate() {
      return true;
    };

  return Form({
    validate: (value: string, props: ToType) =>
      composeValidation(validateForm, validateModel(modelDefinition))(
        value,
        props,
      ),
    convertIn: convertIn(modelDefinition),
    convertOut: convertOut(modelDefinition),
  });
};

export const getDirectPatientName = (
  auctionRequest: AuctionRequest | null | undefined,
  translations: Translations,
): string | null => {
  if (!auctionRequest) return "";

  const isProviderSearchRequest = !!auctionRequest.is_provider_search_request;

  return isProviderSearchRequest
    ? getProviderSearchAccountName({
        translations,
        patientId: auctionRequest.auction?.patient?.user_id,
      })
    : null;
};

export const MessageFormView = ({
  auctionRequest,
  elementName,
  withTermsAndConditions,
}: {
  auctionRequest: AuctionRequest;
  elementName: string;
  withTermsAndConditions?: boolean;
}) => {
  const translations = useTranslations();
  const { isSealdOnly } = useEncryption();
  const decryptedSessionKey = useEncryptionKey();
  if (!auctionRequest?.careprovider) {
    console.error(
      "error no careprovider in auction request in MessageFormView",
    );
    return null;
  }
  const directPatient = getDirectPatientName(auctionRequest, translations);

  return (
    <VerticalLayout>
      <MessageRecipientHint
        careprovider={auctionRequest.careprovider}
        careseeker={auctionRequest.auction?.patient?.careseeker}
        isMessenger
        directPatient={directPatient}
      />
      <EncryptedHint
        activateEncryption={isSealdOnly || decryptedSessionKey != null}
        customMargin={margin(2, 0, -1, 2)}
      />
      <TextFieldWrapper margin={margin(0, 2)}>
        <TextArea
          elementName={elementName}
          label={translations.auctionRequest.typeMessageHereOptional}
          placeholder={
            translations.auctionRequest.acceptOptionalMessagePlaceholder
          }
          showError
          rows={3}
        />
      </TextFieldWrapper>
      {withTermsAndConditions && (
        <VerticalLayout data-testid="actions-box">
          <TermsAndConditions />
        </VerticalLayout>
      )}
    </VerticalLayout>
  );
};

export function MessageArea({
  elementName,
  endAdornment,
  label,
  required,
}: {
  elementName: string;
  endAdornment?: ReactNode;
  label: string;
  required?: boolean;
}) {
  return (
    <BannerTextAreaBox>
      <TextAreaInputField
        rows={9}
        width={BANNER_TEXT_AREA_WIDTH}
        label={label}
        elementName={elementName}
        required={required}
        endAdornment={endAdornment}
      />
    </BannerTextAreaBox>
  );
}
