import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { QueryProgress } from "@recare/core/types";
import { FormWatcher } from "@recare/react-forms-state";
import { useTranslations } from "@recare/translations";
import InfoCaption from "ds/components/InfoCaption";
import RSButton from "ds/components/RSButton";
import TextAreaInputField from "ds/components/TextArea/TextAreaForm";
import { margin, padding } from "ds/materials/metrics";

export default function TextAreaDialog({
  caption,
  disableOnEmpty,
  elementName,
  onClose,
  onSubmit,
  open,
  placeholder,
  queryProgress,
  submitText,
  subtitle,
  title,
}: {
  caption?: string;
  disableOnEmpty?: boolean;
  elementName: string;
  onClose: () => void;
  onSubmit: () => void;
  open: boolean;
  placeholder: string;
  queryProgress: QueryProgress;
  submitText: string;
  subtitle?: string;
  title: string;
}) {
  const translations = useTranslations();

  return (
    <Dialog open={open} fullWidth onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {subtitle && <DialogContentText>{subtitle}</DialogContentText>}
        <TextAreaInputField
          rows={6}
          elementName={elementName}
          placeholder={placeholder}
          fullWidth
        />
        {caption && <InfoCaption margin={margin(1, 0)} text={caption} />}
      </DialogContent>
      <DialogActions>
        <RSButton
          color="primary"
          id="cancel"
          loading="na"
          onClick={onClose}
          variant="text"
        >
          {translations.actions.cancel}
        </RSButton>
        <FormWatcher watchPath={elementName}>
          {({ watchedValue }: { watchedValue: string | null | undefined }) => {
            return (
              <RSButton
                color="primary"
                disabled={disableOnEmpty && !watchedValue}
                id="submit"
                inverted
                loading={queryProgress}
                onClick={onSubmit}
                style={{ padding: padding(0, 2) }}
                variant="text"
              >
                {submitText}
              </RSButton>
            );
          }}
        </FormWatcher>
      </DialogActions>
    </Dialog>
  );
}
