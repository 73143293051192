import { getName, OntologyType } from "@recare/core/model/utils/ontologies";
import { useGetOntologies } from "@recare/core/model/utils/ontologies/hooks";
import { useTranslations } from "@recare/translations";
import CheckboxGroup, {
  CheckboxGroupBaseProps,
} from "ds/components/CheckboxGroup";
import { getOrderedItems } from "ds/components/ChipGroup";
import { CSSProperties } from "react";

export type OntologyCheckboxGroupProps = CheckboxGroupBaseProps & {
  captions?: AnyObject;
  labelStyle?: CSSProperties;
  order?: AnyObject;
  required?: boolean;
  sort?: boolean;
  type: OntologyType;
};

export function useOntologyCheckboxGroup({
  captions,
  order,
  sort,
  type,
}: Pick<OntologyCheckboxGroupProps, "captions" | "sort" | "type" | "order">) {
  const getOntologies = useGetOntologies();
  let values = getOntologies({ type, sort });
  values = getOrderedItems(order, values);

  return values.map((item) => ({
    id: item.value,
    label: item.name,
    RadioCaption:
      captions &&
      Object.keys(captions).filter((key: ToType) => key == item.value)
        .length === 1
        ? captions[item.value]
        : undefined,
  }));
}

export default function OntologyCheckboxGroup({
  captions,
  elementName,
  exclude,
  formControlStyle,
  include,
  label,
  labelStyle,
  labelWrapperStyle,
  order,
  required,
  sideMutation,
  sort,
  type,
  wrapperStyle,
}: OntologyCheckboxGroupProps) {
  const translations = useTranslations();
  const items = useOntologyCheckboxGroup({ sort, order, captions, type });

  return (
    <CheckboxGroup
      elementName={elementName}
      exclude={exclude}
      formControlStyle={formControlStyle}
      include={include}
      items={items}
      label={label != null ? label : getName(type, translations)}
      labelStyle={labelStyle}
      labelWrapperStyle={labelWrapperStyle}
      required={required}
      sideMutation={sideMutation}
      wrapperStyle={wrapperStyle}
    />
  );
}
