import LockIcon from "@mui/icons-material/Lock";
import WarningIcon from "@mui/icons-material/Warning";
import { useTranslations } from "@recare/translations";
import { ERROR_COLOR, GREY_600 } from "ds/materials/colors";
import { HorizontalLayout } from "ds/materials/layouts";
import { margin, sizing } from "ds/materials/metrics";
import { Body, FONT_SIZE_16 } from "ds/materials/typography";

export const EncryptedHint = ({
  activateEncryption,
  customMargin,
}: {
  activateEncryption: boolean;
  customMargin?: string;
}) => {
  const translations = useTranslations();

  return (
    <HorizontalLayout aligned style={{ margin: customMargin }}>
      {activateEncryption ? (
        <>
          <LockIcon
            htmlColor={GREY_600}
            style={{
              margin: margin(0, 0, 3, 0),
              fontSize: FONT_SIZE_16,
              color: GREY_600,
            }}
          />
          <Body
            as="p"
            color={GREY_600}
            margin={margin(0, 1)}
            lineHeight={sizing(2.5)}
            data-testid="encrypted-message-hint"
            style={{ wordBreak: "break-word" }}
          >
            {translations.auctionResponse.sendEncryptedMessageHint}
          </Body>
        </>
      ) : (
        <>
          <WarningIcon
            htmlColor={ERROR_COLOR}
            style={{ margin: margin(0, 0, 3, 0), fontSize: FONT_SIZE_16 }}
          />
          <Body
            margin={margin(0, 1)}
            lineHeight={sizing(2.5)}
            as="p"
            style={{ wordBreak: "break-word" }}
          >
            {translations.messenger.notEncryptedHint}
          </Body>
        </>
      )}
    </HorizontalLayout>
  );
};
