import { BaseEventSchema } from "@recare/core/validationSchemas/tracking";
import { SpinnerPage } from "ds/components/Spinner";
import { AuctionRequestContext } from "dsl/atoms/Contexts";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { useTracking } from "react-tracking";

export default function AuctionRequestHome({
  auctionRequestId,
  careproviderId,
}: {
  auctionRequestId: number;
  careproviderId: number | null | undefined;
}) {
  const { Track } = useTracking<DeepPartial<BaseEventSchema>>({
    user_information: { auction_request_id: auctionRequestId },
  });

  return (
    <Track>
      <AuctionRequestContext.Provider
        value={{
          auctionRequestId,
          careproviderId,
        }}
      >
        <Suspense
          fallback={<SpinnerPage id="suspense-careprovider-router-settings" />}
        >
          <Outlet />
        </Suspense>
      </AuctionRequestContext.Provider>
    </Track>
  );
}
