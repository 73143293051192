export const SHADOW_DP1 =
  "0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.12),0px 0px 2px 0px rgba(0, 0, 0, 0.14)";
export const SHADOW_DP2 =
  "0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.12),0px 2px 4px 0px rgba(0, 0, 0, 0.14)";
export const SHADOW_DP3 = "0px 4px 10px 0px rgba(0, 0, 0, 0.1)";
export const SHADOW_DP4 =
  "0px 6px 16px 0px rgba(0, 0, 0, 0.12), 0px -1px 8px 0px rgba(0, 0, 0, 0.12)";
export const SHADOW_DP5 = "0px 2px 4px 0px rgba(0, 0, 0, 0.25)";
export const SHADOW_DP6 =
  "0px 0px 1px 0px rgba(66, 71, 76, 0.50), 0px 4px 8px 0px rgba(66, 71, 76, 0.06), 0px 8px 25px 0px #EBEBEB;";
export const SHADOW_DP7 = "0px 1px 8px 0px rgba(0, 0, 0, 0.25)";
export const SHADOW_DP8 = "0px -2px 8px 0px rgba(0, 0, 0, 0.08)";
export const SHADOW_DP9 = "0px 4px 16px 0px rgba(0, 0, 0, 0.15)";
export const SHADOW_DP10 =
  "0px 0px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.2)";

export const SHADOW_DROP_SMALL = "0px 4px 10px rgba(119, 85, 119, 0.2)";

export const SHADOW_BUTTON =
  "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)";

export const SHADOW_BUTTON_HOVER =
  "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)";

// Material - Animation
export const shadow1Right =
  "1px 0 3px rgba(0,0,0,0.12), 1px 0 2px rgba(0,0,0,0.24)";
export const shadow2Right =
  "3px 0 6px rgba(0,0,0,0.16), 3px 0 6px rgba(0,0,0,0.23)";
export const shadow3Right =
  "10px 0 20px rgba(0,0,0,0.19), 6px 0 6px rgba(0,0,0,0.23)";
export const shadow4Right =
  "14px 0 28px rgba(0,0,0,0.25), 10px 0 10px rgba(0,0,0,0.22)";
export const shadow5Right =
  "19px 0 38px rgba(0,0,0,0.30), 15px 0 12px rgba(0,0,0,0.22)";

export const shadow1Left =
  "1px 0 3px rgba(0,0,0,0.12), 1px 0 2px rgba(0,0,0,0.24)";
export const shadow2Left =
  "3px 0 6px rgba(0,0,0,0.16), 3px 0 6px rgba(0,0,0,0.23)";
export const shadow3Left =
  "6px 0 20px rgba(0,0,0,0.19), 10px 0 6px rgba(0,0,0,0.23)";
export const shadow4Left =
  "10px 0 28px rgba(0,0,0,0.25), 14px 0 10px rgba(0,0,0,0.22)";
export const shadow5Left =
  "15px 0 38px rgba(0,0,0,0.30), 19px 0 12px rgba(0,0,0,0.22)";

export const shadow2dp =
  "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2)";
export const shadow3dp =
  "0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.4)";
export const shadow4dp =
  "0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.4)";
export const shadow6dp =
  "0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.4)";
export const shadow8dp =
  "0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.4)";
export const shadow11dp =
  "0px 11px 15px 0px rgba(0, 0, 0, 0.20), 0px 9px 46px 0px rgba(0, 0, 0, 0.12), 0px 24px 38px 0px rgba(0, 0, 0, 0.14)";
export const shadow12dp =
  "0 12px 16px 1px rgba(0, 0, 0, 0.14), 0 4px 22px 3px rgba(0, 0, 0, 0.12), 0 6px 7px -4px rgba(0, 0, 0, 0.4)";
export const shadow16dp =
  "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0  6px 30px 5px rgba(0, 0, 0, 0.12), 0  8px 10px -5px rgba(0, 0, 0, 0.4)";
export const shadow24dp =
  "0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.4)";

export const shadow1 = shadow2dp;
export const shadow2 = shadow3dp;
export const shadow3 = shadow4dp;
export const shadow4 = shadow6dp;
export const shadow5 = shadow8dp;
export const shadow6 = shadow12dp;
export const shadow7 = shadow16dp;
export const shadow8 = shadow24dp;
