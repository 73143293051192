import lazy from "dsl/atoms/retry";
import { Suspense } from "react";
import useShow from "./useShow";

const MagicLogin = lazy(() => import("./index"));

export default function LazyMagicLogin({
  password,
  username,
}: {
  password: string | undefined;
  username: string | undefined;
}) {
  const shouldShow = useShow();
  if (!shouldShow) return null;

  return (
    <Suspense fallback={<div />}>
      <MagicLogin username={username} password={password} />
    </Suspense>
  );
}
