import {
  AUCTION_REQUEST_ACCEPTED,
  AUCTION_REQUEST_SENT,
} from "@recare/core/consts";
import {
  EncryptedContent,
  FilterValueStartDateRange,
} from "@recare/core/types";
import {
  convertEncryptedFieldIn,
  convertEncryptedFieldOut,
} from "@recare/react-forms-state";
import { Locale as LocaleString } from "@recare/translations";
import Translations from "@recare/translations/types";
import {
  addDays,
  differenceInDays,
  differenceInYears,
  endOfDay,
  format,
  formatDistance,
  fromUnixTime,
  getUnixTime,
  isBefore,
  isFuture,
  isMatch,
  isPast,
  isSameDay,
  isToday,
  isValid,
  parse,
  startOfDay,
  subDays,
} from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import de from "date-fns/locale/de";
import enGB from "date-fns/locale/en-GB";
import fr from "date-fns/locale/fr";

export function getAgeFromDate(date: string) {
  let format = "dd.MM.yyyy";
  if (isMatch(date, "dd/MM/yyyy")) {
    format = "dd/MM/yyyy";
  }
  const birthDate = parse(date, format, new Date());
  return differenceInYears(new Date(), birthDate);
}

export function formatUnixDate(
  unixDate: number,
  locale: LocaleString,
  withoutYear?: boolean,
  customFormat = "P",
) {
  if (withoutYear) {
    const dateFormatWithoutYear = locale === "de" ? "dd.MM." : "dd/MM";
    return format(fromUnixTime(unixDate), dateFormatWithoutYear);
  }
  return format(fromUnixTime(unixDate), customFormat, {
    locale: getLocale(locale),
  });
}

export const getWrittenDate = (
  date: number,
  locale: LocaleString,
  withYear?: boolean,
) =>
  format(fromUnixTime(date), `d. MMMM ${withYear ? "yyyy" : ""}`, {
    locale: getLocale(locale),
  });

export const FORMAT_DATE_TYPE = {
  DIFFERENCE: 1,
  DATE: 2,
  DATE_WITHOUT_YEAR: 3,
};

type FormatDateOptions = {
  withToday: boolean;
  withTomorrow: boolean;
  withYesterday: boolean;
};

const normalizeFormatDateOptions = (options: FormatDateOptions) => ({
  withYesterday:
    typeof options.withYesterday !== "undefined" ? options.withYesterday : true,
  withToday:
    typeof options.withToday !== "undefined" ? options.withToday : true,
  withTomorrow:
    typeof options.withTomorrow !== "undefined" ? options.withTomorrow : true,
});

export const isSameOrBeforeToday = (timestamp: number | null) => {
  if (!timestamp) return false;
  const date = fromUnixTime(timestamp);
  return isPast(date) || isToday(date);
};

export function isFutureDay(unixTimestamp: number) {
  const date = fromUnixTime(unixTimestamp);

  const startOfInputDay = startOfDay(date);
  const startOfToday = startOfDay(new Date());

  const result = isFuture(startOfInputDay) && startOfInputDay > startOfToday;

  return result;
}

export const formatDate = (
  unix: number | null | undefined,
  translations: Translations,
  localeCode?: LocaleString,
  format: number = FORMAT_DATE_TYPE.DIFFERENCE,
  options: FormatDateOptions = {
    withYesterday: true,
    withToday: true,
    withTomorrow: true,
  },
) => {
  if (!unix) return translations.general.missing;

  const { withToday, withTomorrow, withYesterday } =
    normalizeFormatDateOptions(options);

  const date = zonedTimeToUtc(fromUnixTime(unix), "UTC");
  const today = zonedTimeToUtc(new Date(), "UTC");

  if (withToday && isSameDay(date, today)) {
    return translations.general.today;
  }
  const yesterday = zonedTimeToUtc(subDays(new Date(), 1), "UTC");

  if (withYesterday && isSameDay(date, yesterday)) {
    return translations.general.yesterday;
  }

  const tomorrow = zonedTimeToUtc(addDays(new Date(), 1), "UTC");

  if (withTomorrow && isSameDay(date, tomorrow)) {
    return translations.general.tomorrow;
  }

  if (format === FORMAT_DATE_TYPE.DATE && localeCode) {
    return formatUnixDate(getUnixTime(date), localeCode);
  }

  if (format === FORMAT_DATE_TYPE.DATE_WITHOUT_YEAR && localeCode) {
    return formatUnixDate(getUnixTime(date), localeCode, true);
  }

  return formatDistance(startOfDay(date), startOfDay(today), {
    locale: getLocale(localeCode ?? "de"),
  });
};

export const getDateDifferenceFromToday = (
  date: number,
  options?: { fromStartOfDay: boolean } | undefined,
) => {
  let today = zonedTimeToUtc(new Date(), "UTC");
  let utcDate = fromUnixTime(date);
  if (options?.fromStartOfDay) {
    today = startOfDay(today);
    utcDate = startOfDay(utcDate);
  }
  const duration = differenceInDays(utcDate, today);
  return duration;
};

export const getCareStartDate = (
  date: number | null | undefined,
  status: number | null | undefined,
  withPastDates = false,
) => {
  if (!date) return null;

  if (status != AUCTION_REQUEST_SENT && status != AUCTION_REQUEST_ACCEPTED) {
    return date;
  }

  if (!withPastDates) {
    const today = zonedTimeToUtc(startOfDay(new Date()), "UTC");
    return date < getUnixTime(today) ? getUnixTime(today) : date;
  }

  return date;
};

export const getDefaultReportStartDate = () =>
  getUnixTime(startOfDay(subDays(new Date(), 30)));

export const getDefaultReportEndDate = () =>
  getUnixTime(startOfDay(new Date()));

export const isPastDate = (
  v: number | null | undefined,
  days?: number,
): boolean => {
  // - by default checks if current day is equal today or is before it
  // - when provide days argument checks if date is equal day difference
  // from today or is before it
  if (v == null) return false;
  const date = days
    ? addDays(zonedTimeToUtc(new Date(), "UTC"), days)
    : zonedTimeToUtc(new Date(), "UTC");
  const unixValue = fromUnixTime(v);

  return isSameDay(unixValue, date) || isBefore(unixValue, date);
};

export const getLocale = (locale?: LocaleString): Locale => {
  switch (locale) {
    case "de":
      return de;
    case "en":
      return enGB;
    case "fr":
      return fr;
    default:
      return de;
  }
};

export const convertBirthDateIn = (
  encryptedDate: EncryptedContent | { decrypted: string } | null | undefined,
  props: AnyObject & { locale: LocaleString },
) => {
  if (!encryptedDate) return null;
  const decryptedDate: string = convertEncryptedFieldIn(encryptedDate);
  const birthDate: Date = convertStringToDate(decryptedDate, "de");
  if (isValid(birthDate))
    return format(birthDate, "P", { locale: getLocale(props?.locale) });
  return null;
};

export const convertBirthDateOut = (
  formattedDate: string,
  props: AnyObject & { locale: LocaleString },
) => {
  if (!formattedDate) return null;
  return convertEncryptedFieldOut(
    format(convertStringToDate(formattedDate, props?.locale), "dd.MM.yyyy"),
  );
};

export const convertBirthDateToFhir = (formattedDate: string) => {
  const dateValue = convertStringToDate(formattedDate, "de");
  if (isValid(dateValue)) return format(dateValue, "yyyy-MM-dd");
  return null;
};

export const convertStringToDate = (
  formattedDate: string,
  locale: LocaleString,
): Date => {
  let format = "dd.MM.yyyy";
  if (isMatch(formattedDate, "dd/MM/yyyy")) {
    format = "dd/MM/yyyy";
  }
  const date = parse(formattedDate, format, new Date(), {
    locale: getLocale(locale),
  });

  return date;
};

export const validateBirthDate = (
  formattedDate: string,
  { locale }: AnyObject,
) => {
  if (!formattedDate) return false;

  const birthDate = convertStringToDate(formattedDate, locale);
  return (
    isValid(birthDate) &&
    isPast(birthDate) &&
    formattedDate.length >= "MM.DD.YYYY".length
  );
};

export const formatFromNow = (
  timestamp: Date | number,
  locale: LocaleString,
) => {
  if (!timestamp) return "";
  const date =
    typeof timestamp === "number" ? fromUnixTime(timestamp) : timestamp;
  return formatDistance(date, new Date(), {
    addSuffix: true,
    locale: getLocale(locale),
  });
};

export function getDateValuesForFilters(
  locale: LocaleString,
  values: FilterValueStartDateRange | undefined,
  translations: Translations,
): string {
  if (values) {
    if (values.from_date && values.to_date) {
      return `${formatDate(
        values.from_date,
        translations,
        locale,
        FORMAT_DATE_TYPE.DATE,
        { withToday: false, withTomorrow: false, withYesterday: false },
      )} - ${formatDate(
        values.to_date,
        translations,
        locale,
        FORMAT_DATE_TYPE.DATE,
        { withToday: false, withTomorrow: false, withYesterday: false },
      )}`;
    }
    if (values.from_date && !values.to_date) {
      return `${translations.general.fromDate} ${formatDate(
        values.from_date,
        translations,
        locale,
        FORMAT_DATE_TYPE.DATE,
        { withToday: false, withTomorrow: false, withYesterday: false },
      )}`;
    }

    if (!values.from_date && values.to_date) {
      return `${translations.general.untilDate} ${formatDate(
        values.to_date,
        translations,
        locale,
        FORMAT_DATE_TYPE.DATE,
        { withToday: false, withTomorrow: false, withYesterday: false },
      )}`;
    }
  }
  return "";
}

export function getDateValue(
  value: number,
  locale: Locale,
  withTime?: boolean,
): string {
  if (isValid(value)) {
    if (withTime) {
      return format(fromUnixTime(value), "PPp", { locale });
    }
    return format(fromUnixTime(value), "PP", { locale });
  }
  return String(value);
}

export const getDateFromUnixString = (
  date: string | null | undefined,
): Date | null => {
  if (!date) return null;

  const unix = parseInt(date);
  if (isNaN(unix)) {
    return null;
  }

  const parsed = fromUnixTime(unix);
  if (!isValid(parsed)) {
    return null;
  }

  return parsed;
};

export const addDaysUnixUtc = (
  days: number,
  options?: { endOfDay?: boolean; startOfDay?: boolean },
) => {
  const date = options?.endOfDay
    ? endOfDay(new Date())
    : options?.startOfDay
    ? startOfDay(new Date())
    : new Date();

  return getUnixTime(zonedTimeToUtc(addDays(date, days), "UTC"));
};
