import LockIcon from "@mui/icons-material/Lock";
import WarningIcon from "@mui/icons-material/Warning";
import { useTranslations } from "@recare/translations";
import Tooltip from "ds/components/Tooltip";
import { ERROR_COLOR, ICON_DARK } from "ds/materials/colors";
import { FONT_SIZE_16 } from "ds/materials/typography";
import { useState } from "react";

export function EncryptedTooltip({
  activateEncryption,
  isFile = false,
}: {
  activateEncryption: boolean;
  isFile?: boolean;
}) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const translations = useTranslations();

  if (!activateEncryption)
    return (
      <Tooltip
        title={translations.messenger.notEncryptedHint}
        placement="right-start"
      >
        <WarningIcon
          htmlColor={ERROR_COLOR}
          style={{ fontSize: FONT_SIZE_16 }}
        />
      </Tooltip>
    );

  return (
    <div
      role="tooltip"
      tabIndex={0}
      onFocus={() => setTooltipOpen(true)}
      onBlur={() => setTooltipOpen(false)}
      onMouseEnter={() => setTooltipOpen(true)}
      onMouseLeave={() => setTooltipOpen(false)}
      aria-label={
        isFile
          ? translations.messenger.fileTransferEncrypted
          : translations.auctionResponse.sendEncryptedMessageHint
      }
    >
      <Tooltip
        title={
          isFile
            ? translations.messenger.fileTransferEncrypted
            : translations.auctionResponse.sendEncryptedMessageHint
        }
        placement="top-start"
        open={tooltipOpen}
      >
        <LockIcon htmlColor={ICON_DARK} style={{ fontSize: FONT_SIZE_16 }} />
      </Tooltip>
    </div>
  );
}

export default EncryptedTooltip;
