import { useIntParams } from "@recare/core/model/utils/urls";
import { Patient } from "@recare/core/types";
import { GetAuctionRequestQuery } from "apollo/queries";
import BarthelIndexDetailsSummaryPage from "dsl/organisms/BarthelIndex/detailsSummaryPage";
import EarlyRehabIndexDetailsSummaryPage from "dsl/organisms/BarthelIndex/earlyRehabSummaryPage";

export function ConnectedBarthelIndex() {
  const { param } = useIntParams<{ param: number }>({
    param: -1,
  });

  return (
    <GetAuctionRequestQuery fetchPolicy="cache-first" auctionRequestId={param}>
      {(auctionRequest) => {
        const patient: Patient | undefined = auctionRequest?.auction?.patient;
        return patient ? (
          <BarthelIndexDetailsSummaryPage
            patient={patient}
            auctionId={auctionRequest?.auction?.id ?? -1}
          />
        ) : null;
      }}
    </GetAuctionRequestQuery>
  );
}

export function ConnectedEarlyRehabIndex() {
  const { param } = useIntParams<{ param: number }>({
    param: -1,
  });

  return (
    <GetAuctionRequestQuery fetchPolicy="cache-first" auctionRequestId={param}>
      {(auctionRequest) => {
        const patient = auctionRequest?.auction?.patient;
        return patient ? (
          <EarlyRehabIndexDetailsSummaryPage
            patient={patient}
            auctionId={auctionRequest?.auction.id ?? -1}
          />
        ) : null;
      }}
    </GetAuctionRequestQuery>
  );
}
