import { Theme } from "@mui/material/styles";
import { dp } from "./metrics";

/*
 * Color palette
 * Avoid importing these, instead import a feature color below in different files.
 */

// Material Design color palettes
const CYAN_600 = "#00ACC1";
const CYAN_100 = "#EBF8FA";
const CYAN_100_WITH_OPACITY = "#EBF8FA99";
const CYAN_700 = "#0097A7";
const AMBER_A400 = "#FCEC7D";
const AMBER_A700 = "#EDC843";
const GREEN_A700 = "#007A2B";
const RED_A700 = "#E13023";

// Custom Design color palettes
const CUSTOM_COLOR_1 = "#002258";
const CUSTOM_COLOR_2 = "#55A1E8";
const CUSTOM_COLOR_3 = "#EBF3FF";
const CUSTOM_COLOR_4 = "#3C6D82";
const CUSTOM_COLOR_5 = "#74B3CE";
const CUSTOM_COLOR_6 = "#DBF4FF";
const CUSTOM_COLOR_7 = "#FFFAD6";
const CUSTOM_COLOR_8 = "#01531E";
const CUSTOM_COLOR_9 = "#EAF2EA";
const CUSTOM_COLOR_10 = "#FCEBEB";
const CUSTOM_COLOR_11 = "#3A7099";
const CUSTOM_COLOR_12 = "#007D8A";
const CUSTOM_COLOR_13 = "#800000";
const CUSTOM_COLOR_14 = "#0000FF";
const CUSTOM_COLOR_15 = "#93B7FB";
const CUSTOM_COLOR_16 = "#233684";
const CUSTOM_COLOR_17 = "#B4D7FE";

export const WHITE = "#FFF";

export const CUSTOM_WHITE = "#F6F3EF";

export const WHITE_WITH_OPACITY = "rgba(255, 255, 255, 0.7)";
export const GREY_50 = "#FAFAFA";
export const GREY_100 = "#F5F5F5";
export const GREY_200 = "#EEEEEE";
export const GREY_300 = "#E0E0E0";
export const GREY_400 = "#CCCCCC";
export const GREY_500 = "#9E9E9E";
export const GREY_600 = "#6C6C6C";

export const BLACK = "#000000";
export const CUSTOM_BLACK = "#212121";
export const CUSTOM_BLACK_1 = "#000000DE";
export const CUSTOM_BLACK_2 = "#00000099";
export const CUSTOM_BLACK_3 = "#0000000A";

// New brand colors
export const BRAND_PRIMARY_MAIN_COLOR = "#3E73DE";

/*
 * Feature colors
 */

/* Theme */
//primary
export const PRIMARY_DARK_COLOR = CYAN_700;
export const PRIMARY_COLOR = CYAN_600;
export const PRIMARY_LIGHT_COLOR = CYAN_100;

export const PROVIDER_PRIMARY_DARK_COLOR = CUSTOM_COLOR_1;
export const PROVIDER_PRIMARY_COLOR = CUSTOM_COLOR_2;
export const PROVIDER_PRIMARY_LIGHT_COLOR = CUSTOM_COLOR_3;

export const ACP_PRIMARY_DARK_COLOR = CUSTOM_COLOR_4;
export const ACP_PRIMARY_COLOR = CUSTOM_COLOR_5;
export const ACP_PRIMARY_LIGHT_COLOR = CUSTOM_COLOR_6;

//secondary
export const ACCENT_DARK_COLOR = AMBER_A700;
export const ACCENT_COLOR = AMBER_A400;
export const ACCENT_LIGHT_COLOR = CUSTOM_COLOR_7;

//error
export const ERROR_COLOR = RED_A700;
export const ERROR_LIGHT_COLOR = CUSTOM_COLOR_10;
export const ERROR_DARK_COLOR = CUSTOM_COLOR_13;

//success
export const SUCCESS_COLOR = GREEN_A700;
export const SUCCESS_LIGHT_COLOR = CUSTOM_COLOR_9;
export const SUCCESS_DARK_COLOR = CUSTOM_COLOR_8;

//warning
export const WARNING_COLOR = AMBER_A700;
export const WARNING_LIGHT_COLOR = AMBER_A400;
export const WARNING_DARK_COLOR = CUSTOM_COLOR_7;

/* others*/
//Disabled
export const DISABLED_COLOR = GREY_400;
export const DISABLED_BACKGROUND_COLOR = GREY_300;

//Unavailable
export const REQUEST_UNAVAILABLE_COLOR = GREY_400;
export const UNAVAILABLE_GREY = GREY_500;

//Text
export const TEXT_DISABLED_COLOR = GREY_500;
export const TEXT_DARK_PRIMARY = CUSTOM_BLACK;
export const TEXT_DARK_SECONDARY = CUSTOM_BLACK;
export const TEXT_LIGHT_SECONDARY = WHITE_WITH_OPACITY;
export const TEXT_DARK_HINT = GREY_500;
export const TEXT_ACCENT = ACCENT_COLOR;
export const TEXT_ACCENT_DARK = ACCENT_DARK_COLOR;
export const TEXT_SUCCESS_COLOR = SUCCESS_COLOR;
export const TEXT_ERROR_COLOR = ERROR_COLOR;
export const TEXT_STEPPER = GREY_600;

// New shades of black for search merge
export const TEXT_SUBTITLE = CUSTOM_BLACK_2;
export const TEXT_BODY = CUSTOM_BLACK_1;
export const TABLE_HOVER_GREY = CUSTOM_BLACK_3;
export const TABLE_SELECTED = CYAN_100_WITH_OPACITY;
export const TABLE_SELECTED_HOVER = CYAN_100;

//Icon
export const ICON_DARK = GREY_600;
export const ICON_GREY = GREY_500;
export const ICON_LIGHT_GREY = GREY_400;
export const ICON_VERY_LIGHT_GREY = GREY_300;
export const ICON_LIGHT = WHITE;

//Message Bubble
export const MESSAGING_BUBBLE_RECEIVED = CUSTOM_COLOR_15;
export const MESSAGING_BUBBLE_SENT = CUSTOM_COLOR_16;
export const MESSAGING_BUBBLE_BACKGROUND_LIGHT = GREY_200;
export const MESSAGING_BUBBLE_BACKGROUND_ACCENT = ACCENT_LIGHT_COLOR;
export const MESSAGING_BUBBLE_BACKGROUND_DARK = PRIMARY_DARK_COLOR;

//Info card
export const INFO_CARD_BACKGROUND = GREY_200;
export const INFO_CARD_BORDER_BOTTOM = GREY_300;
export const CARD_BACKGROUND_LIGHT = GREY_50;
export const CARD_BACKGROUND = GREY_300;
export const CARD_BORDER = GREY_300;
export const CARD_BORDER_LIGHT = GREY_100;

//Chip
export const CHIP_BACKGROUND = GREY_300;
export const CHIP_INFO = GREY_400;

//Diff Background
export const DIFF_BACKGROUND_GREEN = SUCCESS_LIGHT_COLOR;
export const DIFF_BACKGROUND_RED = ERROR_LIGHT_COLOR;

//Border
export const BORDER_COLOR = GREY_300;
export const BORDER_BOTTOM = GREY_300;
export const BORDER_BOTTOM_DARK = GREY_600;

//Outline
export const KEYBOARD_FOCUS_OUTLINE = CUSTOM_COLOR_14;

// Text selection
export const INPUT_TEXT_SELECTION = CUSTOM_COLOR_17;

//APP Background Color
export const APP_BACKGROUND = GREY_50;
export const APP_BACKGROUND_DARK = GREY_100;
export const SUB_HEADER_BAR_BACKGROUND = GREY_100;

//FAB
export const FAB_DEFAULT_BACKGROUND = GREY_600;

//Badge
export const STATUS_BADGE_MEDIUM_GREY = GREY_500;

//Button
export const BUTTON_DEFAULT_BACKGROUND = GREY_500;

//Hint Nudge
export const HINT_NUDGE_BACKGROUND = CYAN_100;

//Color
export const LIGHT_GREY = GREY_300;
export const MEDIUM_GREY = GREY_600;

//Only for Mobile
export const DARK_BLUE = "#2F5A9E";

// B2C
export const B2C_PRIMARY_COLOR = CUSTOM_COLOR_11;
export const STEPPER_INACTIVE = CUSTOM_COLOR_12;

//Bulk Action
export const BULK_ACTION__BACKGROUND = GREY_100;

export const BULK_ACTION_BACKGROUND_DIRECT = `repeating-linear-gradient(
  315deg,
  rgb(239, 245, 255),
  rgb(239, 245, 255) ${dp(5)},
  rgb(217, 222, 233) ${dp(5)},
  rgb(217, 222, 233) ${dp(10)}
)`;

/*
 * Config
 */

export const grey = {
  "50": GREY_50,
  "100": GREY_100,
  "200": GREY_200,
  "300": GREY_300,
  "400": GREY_400,
  "500": GREY_500,
  "600": GREY_600,
};

export function configureColor(
  theme: Theme,
  {
    accent,
    black,
    dark,
    disabled,
    error,
    primary,
    success,
    white,
  }: {
    accent?: boolean;
    black?: boolean;
    dark?: boolean;
    disabled?: boolean;
    error?: boolean;
    primary?: boolean;
    success?: boolean;
    white?: boolean;
  },
  mainColor?: string,
  hovered?: boolean,
): string {
  if (primary)
    return dark ? theme.palette.primary.dark : theme.palette.primary.main;
  if (accent) return ACCENT_COLOR;
  if (success) return theme.palette.success.main;
  if (error) return theme.palette.error.main;
  if (disabled) return DISABLED_COLOR;
  if (white)
    return hovered ? hexToRgbA(theme.palette.primary.main, 0.08) : WHITE;
  if (black) return ICON_DARK;
  return mainColor || "transparent";
}

export function hexToRgbA(hex: string, opacity = 1) {
  let c: string | string[] | number;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = parseInt(`0x${c.join("")}`, 16);
    const rgbaStr = [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",");
    return `rgba(${rgbaStr},${opacity})`;
  }
  return hex;
}

// https://gist.github.com/xenozauros/f6e185c8de2a04cdfecf
export function hexToHSL(hex: string, lightenBy = 0) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) return;
  let r = parseInt(result[1], 16);
  let g = parseInt(result[2], 16);
  let b = parseInt(result[3], 16);
  (r /= 255), (g /= 255), (b /= 255);
  const max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h, s;
  let l = (max + min) / 2;
  if (max == min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    if (h) h /= 6;
  }

  if (!h || !s || !l) return;

  h = Math.round(h * 360);
  s = Math.round(s * 100);
  l = Math.round(l * 100) + lightenBy;
  l = l > 100 ? 100 : l;

  let cssString = h + "," + s + "%," + l + "%";
  cssString = "hsl(" + cssString + ")";

  return cssString;
}

export const POSITIVE_DIFF_STYLE = {
  backgroundColor: DIFF_BACKGROUND_GREEN,
};

export const NEGATIVE_DIFF_STYLE = {
  backgroundColor: DIFF_BACKGROUND_RED,
  textDecoration: "line-through",
};

/**
  Add opacity to theme hex colours
   * @param {string} color - The hex colour value.
   * @param {number} opacity - The opacity from 0 - 99.
  **/
export const hexWithOpacity = (color: string, opacity: number) =>
  `${color}${
    opacity < 10 ? `0${opacity}` : opacity > 99 ? "99" : opacity.toString()
  }`;

export const RADIO_WHITE_BACKGROUND = {
  background:
    "radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 80%)",
};
