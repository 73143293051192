import { ApolloError, BaseMutationOptions } from "@apollo/client";
import {
  APP_PROVIDER_SEARCH,
  QUERY_PROGRESS_FAILED,
  QUERY_PROGRESS_NOT_STARTED,
  QUERY_PROGRESS_PENDING,
  QUERY_PROGRESS_SUCCEED,
} from "@recare/core/consts";
import { getEncryptedMessage } from "@recare/core/model/crypto/cryptoService";
import { getUndoActionType } from "@recare/core/model/requests";
import {
  getApolloError,
  getErrorMessage,
} from "@recare/core/model/utils/errors";
import { getReferrer } from "@recare/core/model/utils/urls";
import { encryptForSession } from "@recare/core/seald/sessions";
import {
  AuctionRequest,
  EncryptionContext,
  QueryProgress,
  RequestActionType,
  SearchActionType,
  UndoActionType,
} from "@recare/core/types";
import { useRequestActionMutation } from "apollo/hooks/mutations";
import { useEnvContext } from "context/EnvContext";
import { useAuctionRequestEventsContext } from "dsl/atoms/AuctionRequestEventsContext";
import { useEncryption, useEncryptionKey } from "dsl/atoms/Contexts";
import { setUpdatedRequest } from "dsl/ecosystems/AuctionRequest/updatedRequest";
import { useCallback, useState } from "react";

/** @deprecated To be removed when old encrytion gets decommissioned */
export const getEncryptedMessageContext = async ({
  actionType,
  decryptedSessionKey,
  encryptionContext,
  message,
}: {
  actionType: RequestActionType | SearchActionType | UndoActionType;
  decryptedSessionKey: CryptoKey | null;
  encryptionContext: EncryptionContext | undefined;
  message?: string;
}) => {
  let encryptedMessageData:
    | {
        encryptedMessage: string;
        message_iv?: string;
        sealdEncryptedMessage?: string | undefined;
      }
    | undefined;
  if (message != null && decryptedSessionKey != null) {
    try {
      encryptedMessageData = await getEncryptedMessage(
        message,
        decryptedSessionKey,
      );
      encryptedMessageData.sealdEncryptedMessage = await encryptForSession({
        message,
        sessionId: encryptionContext?.seald_id,
        context: `message for ${actionType}`,
      });
    } catch (error) {
      console.error(`Failed to encrypt message for ${actionType} - `, error);
    }
  }
  return encryptedMessageData
    ? {
        message: encryptedMessageData.encryptedMessage,
        seald_message: encryptedMessageData?.sealdEncryptedMessage,
        message_iv: encryptedMessageData?.message_iv,
      }
    : {};
};

export const getSealdEncryptedMessageContext = async ({
  actionType,
  encryptionContext,
  message,
}: {
  actionType: RequestActionType | SearchActionType | UndoActionType;
  encryptionContext: EncryptionContext | undefined;
  message?: string;
}) => {
  if (message == null) {
    return {};
  }

  try {
    const seald_message = await encryptForSession({
      message,
      sessionId: encryptionContext?.seald_id,
      context: `message for ${actionType}`,
    });

    return { seald_message };
  } catch (error) {
    console.error(`Failed to encrypt message for ${actionType} - `, error);
  }

  return {};
};

type CallRequestActionProps = {
  context?: Record<string, any>;
  message?: string;
  onCompleted?: (args?: AuctionRequest | null) => void;
  onError?: (err: ApolloError | undefined) => void;
};

const useGetRefetchQueries = ():
  | BaseMutationOptions["refetchQueries"]
  | undefined => {
  const { eventsQuery } = useAuctionRequestEventsContext();
  const { app } = useEnvContext();

  const refetchQueries: BaseMutationOptions["refetchQueries"] = eventsQuery
    ? [eventsQuery]
    : [];

  if (app === APP_PROVIDER_SEARCH) {
    refetchQueries.push("getProviderSearchPatients");
  }

  return refetchQueries?.length ? refetchQueries : undefined;
};

const useRequestAction = ({
  actionType,
  auctionRequest,
}: {
  actionType: RequestActionType | UndoActionType;
  auctionRequest: AuctionRequest;
}) => {
  const { isSealdOnly } = useEncryption();
  const refetchQueries = useGetRefetchQueries();
  const [requestAction] = useRequestActionMutation({
    actionType,
    requestId: auctionRequest.id,
    refetchQueries,
  });

  const decryptedSessionKey = useEncryptionKey();
  const [queryProgress, setQueryProgress] = useState<QueryProgress>(
    QUERY_PROGRESS_NOT_STARTED,
  );

  const callAction = useCallback(
    async ({
      message,
      onCompleted,
      context = {},
      onError,
    }: CallRequestActionProps) => {
      setQueryProgress(QUERY_PROGRESS_PENDING);
      if (!auctionRequest) {
        const error = `request passed to useRequestAction is missing for actionType ${actionType}`;
        console.error(error);
        setQueryProgress(QUERY_PROGRESS_FAILED);
        throw new Error(error);
      }

      try {
        const messageData = isSealdOnly
          ? await getSealdEncryptedMessageContext({
              message,
              actionType,
              encryptionContext: auctionRequest?.seald_encryption_context,
            })
          : await getEncryptedMessageContext({
              message,
              decryptedSessionKey,
              actionType,
              encryptionContext: auctionRequest?.seald_encryption_context,
            });

        const { data } =
          (await requestAction(
            {
              request_id: auctionRequest.id,
              ref: getReferrer(),
              context: {
                ...context,
                ...messageData,
              },
            },
            { id: auctionRequest.id },
          )) || {};

        const requestActionResponse = data?.requestAction;

        if (requestActionResponse) setUpdatedRequest(requestActionResponse.id);

        if (onCompleted) onCompleted(requestActionResponse);
        setQueryProgress(QUERY_PROGRESS_SUCCEED);

        return requestActionResponse;
      } catch (err) {
        setQueryProgress(QUERY_PROGRESS_FAILED);
        console.error(`${actionType} - `, getErrorMessage(err));
        if (onError) onError(getApolloError(err));
        return null;
      }
    },
    [requestAction, auctionRequest],
  );

  return [callAction, queryProgress] as const;
};

export const useUndoRequestAction = ({
  auctionRequest,
}: {
  auctionRequest: AuctionRequest;
}) => {
  const undoActionType = getUndoActionType({
    requestActions: auctionRequest.request_actions,
  });
  const [undoAction, queryProgress] = useRequestAction({
    actionType: undoActionType,
    auctionRequest,
  });
  return [undoAction, queryProgress, undoActionType] as const;
};

export default useRequestAction;
