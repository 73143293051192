import {
  ApolloError,
  WatchQueryFetchPolicy,
  useApolloClient,
} from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import {
  GET_ACCOUNT,
  GET_AUCTIONREQUEST,
  GET_AUCTION_REQUESTS_MAP,
  GET_AUCTION_REQUESTS_V2,
  GET_CAREPROVIDER,
  GET_CAREPROVIDER_TOKEN,
  GET_CARESEEKER,
  GET_CARESEEKER_ACCOUNTS,
  GET_CARESEEKER_INTERCOM,
  GET_RECEIVER_ACCOUNTS,
  GET_SOCIAL_WORKERS,
} from "@recare/core/apollo/graphql";
import { useApolloEncryptionContext } from "@recare/core/model/patients/encryption/utils";
import { getErrorMessage } from "@recare/core/model/utils/errors";
import { getReferrer } from "@recare/core/model/utils/urls";
import {
  Account,
  AuctionRequest,
  Careprovider,
  Careseeker,
  QueryProgress,
} from "@recare/core/types";
import { Result } from "apollo/utils";
import { ReactNode } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { compose, withProps } from "recompose";
import {
  ApolloLoading,
  getQueryProgress,
  toHoc,
  useNoCacheData,
} from "./utils";

const useUpdateUrlForRecommendations = (requestedRequestID: number) => {
  const navigate = useNavigate();
  const client = useApolloClient();

  return ({
    auctionRequest,
  }: {
    auctionRequest: AuctionRequest | undefined;
  }) => {
    if (!auctionRequest?.id || auctionRequest.id === requestedRequestID) return;

    client.writeQuery({
      query: GET_AUCTIONREQUEST,
      variables: {
        id: auctionRequest.id,
        ref: getReferrer(),
      },
      data: {
        auctionRequest,
      },
    });

    navigate(
      {
        pathname: window.location.pathname.replace(
          `/${requestedRequestID}`,
          `/${auctionRequest.id}`,
        ),
        search: location.search,
      },
      { replace: true },
    );
  };
};

const useGetAuctionRequestFetchPolicy = ({
  fetchPolicy,
}: {
  fetchPolicy?: WatchQueryFetchPolicy;
}): WatchQueryFetchPolicy => {
  const [search] = useSearchParams();

  const isRecommendation = search.get("recommendation");

  if (isRecommendation) {
    return "cache-first";
  }

  return fetchPolicy ?? "network-only";
};

export const GetAuctionRequestQuery = ({
  auctionRequestId,
  children,
  fetchPolicy,
}: {
  auctionRequestId: number;
  children: (
    auctionRequest: Readonly<AuctionRequest> | undefined,
    queryProgress: QueryProgress,
  ) => ReactNode | null;
  fetchPolicy?: WatchQueryFetchPolicy;
}) => {
  const encryptionContext = useApolloEncryptionContext({ decrypt: true });
  const updateUrl = useUpdateUrlForRecommendations(auctionRequestId);
  const policy = useGetAuctionRequestFetchPolicy({
    fetchPolicy,
  });

  return (
    <Query
      query={GET_AUCTIONREQUEST}
      variables={{
        id: auctionRequestId,
        ref: getReferrer(),
      }}
      fetchPolicy={policy}
      skip={auctionRequestId <= 0}
      context={{ encryptionContext }}
      onCompleted={(data: { auctionRequest: AuctionRequest }) => {
        updateUrl({ auctionRequest: data?.auctionRequest });
      }}
    >
      {({
        data,
        error,
        loading,
        refetch,
      }: {
        data: { auctionRequest: AuctionRequest };
        error?: ApolloError;
        loading: boolean;
        refetch: AnyFunction;
      }) => {
        return (
          <ApolloLoading
            loading={loading}
            data={data}
            error={error}
            refetch={refetch}
          >
            {children}
          </ApolloLoading>
        );
      }}
    </Query>
  );
};

export const GetCareproviderQuery = ({
  careproviderId,
  children,
  fetchPolicy,
  token,
  withEvents,
  withInternalNotes,
  withMetrics,
}: {
  careproviderId: number | undefined;
  children: (
    careprovider: Readonly<Careprovider> | undefined,
    queryProgress: QueryProgress,
  ) => ReactNode | null;
  fetchPolicy?: WatchQueryFetchPolicy;
  token?: string | undefined;
  withEvents?: boolean;
  withInternalNotes?: boolean;
  withMetrics?: boolean;
}) => (
  <Query<{ careprovider: Careprovider }>
    query={
      token
        ? GET_CAREPROVIDER_TOKEN({ withEvents, withMetrics, token })
        : GET_CAREPROVIDER({ withEvents, withMetrics, withInternalNotes })
    }
    variables={{ id: careproviderId }}
    fetchPolicy={fetchPolicy}
  >
    {({ data, error, loading }) => {
      const careprovider = data?.careprovider;
      return <>{children(careprovider, getQueryProgress(loading, error))}</>;
    }}
  </Query>
);

export const GetCareproviderQueryHOC = toHoc(
  GetCareproviderQuery,
  "careprovider",
);

export const GetCareseekerQuery = ({
  careseekerId,
  children,
  withAdminFields,
}: {
  careseekerId: number;
  children: (careseeker: Readonly<Careseeker>) => JSX.Element;
  withAdminFields?: boolean;
}) => {
  return (
    <Query<{ careseeker: Careseeker }, { id: number }>
      query={GET_CARESEEKER({ withAdminFields })}
      variables={{ id: careseekerId }}
    >
      {({ data, error, loading }) => {
        return (
          <Result
            data={data?.careseeker}
            getErrorLabel={() => getErrorMessage(error)}
            id="acp-get-careseeker"
            queryProgress={getQueryProgress(loading, error)}
          >
            {children}
          </Result>
        );
      }}
    </Query>
  );
};

export const GetCareseekerIntercomQuery = ({
  careseekerId,
  children,
}: {
  careseekerId: number;
  children: (
    careseeker: Readonly<Careseeker> | undefined,
    queryProgress: QueryProgress,
  ) => ReactNode;
}) => (
  <Query<{ careseeker: Careseeker }>
    query={GET_CARESEEKER_INTERCOM}
    variables={{ id: careseekerId }}
  >
    {({ data, error, loading }) => {
      const careseeker = data?.careseeker;
      return <>{children(careseeker, getQueryProgress(loading, error))}</>;
    }}
  </Query>
);

export const GetCareseekerQueryHOC = compose(
  withProps({ withAdminFields: true }),
  toHoc(GetCareseekerQuery, "careseeker"),
);

export const GetCareseekerAccountsQuery = ({
  careseekerId,
  children,
}: {
  careseekerId: number;
  children: (
    accounts: Readonly<Account[]> | undefined,
    queryProgress: QueryProgress,
  ) => ReactNode;
}) => (
  <Query<{ accounts: Account[] }>
    query={GET_CARESEEKER_ACCOUNTS}
    variables={{ id: careseekerId }}
  >
    {({ data, error, loading }) => {
      const accounts = data?.accounts;
      return <>{children(accounts, getQueryProgress(loading, error))}</>;
    }}
  </Query>
);

export const GetReceiverAccountsQuery = ({
  careproviderId,
  children,
}: {
  careproviderId: number;
  children: (
    accounts: Readonly<Account[]> | undefined,
    queryProgress: QueryProgress,
  ) => ReactNode | null;
}) => (
  <Query<{ accounts: Account[] }>
    query={GET_RECEIVER_ACCOUNTS}
    variables={{ id: careproviderId }}
  >
    {({ data, error, loading }) => {
      const accounts = data?.accounts;
      return <>{children(accounts, getQueryProgress(loading, error))}</>;
    }}
  </Query>
);

export const GetCareseekerAccountsQueryHOC = toHoc(
  GetCareseekerAccountsQuery,
  "accounts",
);

export const GetAccountQuery = ({
  accountId,
  children,
  realTime,
}: {
  accountId: number | null | undefined;
  children: (
    accounts: Readonly<Account> | null | undefined,
    queryProgress: QueryProgress,
  ) => ReactNode | null;
  realTime?: boolean;
}) => {
  return (
    <Query<{ account: Account | null }>
      query={GET_ACCOUNT}
      variables={{ id: accountId }}
      fetchPolicy={realTime ? "network-only" : "cache-first"}
      skip={!accountId || accountId < 0}
    >
      {({ data, error, loading }) => {
        const account = data?.account;
        return <>{children(account, getQueryProgress(loading, error))}</>;
      }}
    </Query>
  );
};

export const GetAccountQueryHOC = toHoc(GetAccountQuery, "account");

export const GetSocialWorkers = ({
  careseekerId,
  children,
}: {
  careseekerId: number;
  children: (
    socialWorkers: Readonly<Account[]> | undefined,
    queryProgress: QueryProgress,
  ) => ReactNode | null;
}) => (
  <Query<{ socialWorkers: Account[] }>
    query={GET_SOCIAL_WORKERS}
    variables={{ careseekerId }}
  >
    {({ data, error, loading }) => {
      const socialWorkers = data?.socialWorkers;
      return <>{children(socialWorkers, getQueryProgress(loading, error))}</>;
    }}
  </Query>
);

export const GetSocialWorkersHOC = toHoc(GetSocialWorkers, "socialWorkers");

export function calculateAuctionRequestQueryVars(
  start?: number,
  count?: number,
  status?: Array<number>,
) {
  const startVar = `${start != null ? `?start=${start}` : ``}`;
  const countVar = `${count ? `&count=${count}` : ``}`;
  const statusVar = status
    ? status.reduce((acc, val) => `${acc || ``}&status=${val}`, "")
    : ``;
  let queryVars = `${startVar}${countVar}${statusVar}`;
  if (queryVars.charAt(0) === "&") queryVars = queryVars.replace("&", "?");
  return queryVars;
}

export const GetAuctionRequestsV2Query = ({
  auctionId,
  children,
  count,
  start,
  status,
}: {
  auctionId: number;
  children: (
    requestsPayload:
      | Readonly<{ requests: Array<AuctionRequest>; total: number }>
      | undefined,
    queryProgress: QueryProgress,
  ) => ReactNode | null;
  count?: number;
  start?: number;
  status: Array<number>;
}) => {
  const queryVars = calculateAuctionRequestQueryVars(start, count, status);

  return (
    <Query<{
      requestsPayload: { requests: Array<AuctionRequest>; total: number };
    }>
      query={GET_AUCTION_REQUESTS_V2}
      variables={{ id: auctionId, queryVars }}
      fetchPolicy="network-only"
    >
      {({ data, error, loading }) => {
        const requestsPayload = data?.requestsPayload;
        return (
          <>{children(requestsPayload, getQueryProgress(loading, error))}</>
        );
      }}
    </Query>
  );
};

export const GetAuctionRequestsMapQuery = ({
  auctionId,
  children,
  count,
  start,
  status,
}: {
  auctionId: number;
  children: (
    requests: Readonly<Array<AuctionRequest>> | undefined,
    queryProgress: QueryProgress,
  ) => ReactNode | null;
  count?: number;
  start?: number;
  status?: Array<number>;
}) => {
  const { response, saveResponse } = useNoCacheData();
  const queryVars = calculateAuctionRequestQueryVars(start, count, status);
  return (
    <Query<{ requests: Array<AuctionRequest> }>
      query={GET_AUCTION_REQUESTS_MAP}
      variables={{ id: auctionId, queryVars }}
      fetchPolicy="no-cache"
    >
      {({ data, error, loading }) => {
        saveResponse(data, loading);
        const requests = response?.requests;
        return <>{children(requests, getQueryProgress(loading, error))}</>;
      }}
    </Query>
  );
};
