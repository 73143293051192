import { DOC, DOCX, PDF, TRACK_EVENTS } from "@recare/core/consts";
import { TrackEventFn } from "@recare/core/types";
import Translations from "@recare/translations/types";
import { bytesToBase64 } from "../crypto";
import { FileMimeType, MAX_FILE_SIZE, mimeTypeToExtensionMap } from "./config";

export function bytesToSizeMB(bytes: number) {
  return bytes / Math.pow(1024, 2);
}

export async function processImageFile(file: File): Promise<HTMLImageElement> {
  const _URL = window.URL || window.webkitURL;
  const img = new Image();
  img.src = _URL.createObjectURL(file);
  return new Promise((resolve, reject) => {
    img.onload = () => resolve(img);
    img.onerror = (e: any) => reject(e);
  });
}

export async function processDocumentFile(file: File): Promise<string> {
  const fileReader = new FileReader();
  fileReader.readAsArrayBuffer(file);

  return new Promise((resolve, reject) => {
    fileReader.onload = (e: any) => {
      const result = e.target.result;
      const bytes = new Uint8Array(result);
      const base64 = bytesToBase64(bytes);
      resolve(base64);
    };
    fileReader.onerror = (e: any) => reject(e);
  });
}

export async function validateFile({
  file,
  fromArchive,
  trackEvent,
  translations,
}: {
  file: File;
  fromArchive?: boolean;
  trackEvent: TrackEventFn;
  translations: Translations;
}) {
  const trackContext = {
    file_size: file.size,
    file_type: file.type,
    file_implementation: "HIS",
  };

  if (file.size === 0) {
    trackEvent({
      name: TRACK_EVENTS.FILE_UPLOAD_ERROR_FILE_SIZE,
      ...trackContext,
    });
    throw new Error(translations.fileUploader.emptyFileError);
  }

  if (file.size > MAX_FILE_SIZE) {
    trackEvent({
      name: TRACK_EVENTS.FILE_UPLOAD_ERROR_FILE_SIZE,
      ...trackContext,
    });
    throw new Error(translations.fileUploader.fileSizeError);
  }

  if (!(fromArchive ? [PDF, DOC, DOCX] : [PDF]).includes(file.type)) {
    trackEvent({
      name: TRACK_EVENTS.FILE_UPLOAD_ERROR_FILE_TYPE,
      ...trackContext,
    });
    throw new Error(translations.fileUploader.fileTypeError);
  }
}

export function getFileMimeType({
  filename,
}: {
  filename: string;
}): FileMimeType | undefined {
  const dotIndex = filename.lastIndexOf(".");
  const extension = dotIndex !== -1 ? filename.slice(dotIndex + 1) : "";
  if (!extension) return;

  const formattedExtension = extension.startsWith(".")
    ? extension
    : `.${extension}`;

  const mimeTypes = Object.keys(mimeTypeToExtensionMap) as FileMimeType[];
  const matchedMimeType = mimeTypes.find(
    (mimeType) =>
      mimeTypeToExtensionMap[mimeType] === formattedExtension.toLowerCase(),
  );

  return matchedMimeType;
}

export function downloadFile({
  content,
  fileName,
  type = PDF,
}: {
  content: ArrayBuffer | BlobPart | Uint8Array | string;
  fileName: string;
  type?: string;
}) {
  try {
    const blob = new Blob([content], { type });
    createAndClickLink({ blob, fileName });
  } catch (error) {
    console.error(`Could not download file: ${fileName}`, error);
  }
}

export function clickLink({
  fileName,
  target = "_blank",
  url,
}: {
  fileName?: string;
  target?: "_blank" | "_self";
  url: string;
}) {
  if (target === "_blank") {
    window.open(url);
    return;
  }

  if (!fileName) return;

  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;

  if (document.body) {
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export function createAndClickLink({
  blob,
  fileName,
  target = "_blank",
}: {
  blob: Blob;
  fileName: string;
  target?: "_blank" | "_self";
}) {
  const type = getFileMimeType({ filename: fileName });

  if (!type) {
    console.error(
      `[createAndClickLink]: Unable to determine the MIME type for the provided filename: ${fileName}`,
    );
  }
  const pdfBlob = new Blob([blob], { type });
  const url = window.URL.createObjectURL(pdfBlob);
  clickLink({ url, target, fileName });
}
