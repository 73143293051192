import { useTranslations } from "@recare/translations";
import { GREY_400 } from "ds/materials/colors";
import { HorizontalLayout } from "ds/materials/layouts";
import { dp, padding } from "ds/materials/metrics";
import {
  Caption,
  FONT_SIZE_20,
  FONT_SIZE_24,
  FONT_SIZE_34,
} from "ds/materials/typography";

export function EmptyV2({
  isMessenger,
  title,
}: {
  isMessenger?: boolean;
  title: string;
}) {
  return (
    <HorizontalLayout
      aligned
      justify="center"
      margin="0 auto"
      minHeight={isMessenger ? dp(60) : dp(0)}
      padding={padding(4, 0)}
      width="100%"
      maxWidth={isMessenger ? dp(220) : "100%"}
    >
      <Caption
        color={GREY_400}
        fontSize={isMessenger ? FONT_SIZE_20 : FONT_SIZE_24}
        whiteSpace="normal"
        textAlign="center"
      >
        {title}
      </Caption>
    </HorizontalLayout>
  );
}

export function Empty({ isMessenger }: { isMessenger?: boolean }) {
  const translations = useTranslations();

  return (
    <HorizontalLayout
      margin="0 auto"
      padding={padding(4, 0)}
      minHeight={dp(150)}
      justify="center"
      maxWidth="100%"
      aligned
    >
      <Caption
        color={GREY_400}
        fontSize={isMessenger ? FONT_SIZE_24 : FONT_SIZE_34}
        whiteSpace="normal"
      >
        {translations.fileSection.fileListComponent.placeholderNothingReceived}
      </Caption>
    </HorizontalLayout>
  );
}
