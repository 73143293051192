import {
  ADD_CAREPROVIDER_MEMBER,
  ADD_CAREPROVIDER_MEMBER_TOKEN,
  BATCH_ADD_PROVIDER_ACCOUNTS,
  CAREPROVIDER_ACCOUNT_ASSOCIATION,
  CREATE_ACCOUNTS_BATCH,
  CREATE_CAREPROVIDER,
  CREATE_PENDING_SESSION_KEY,
  FORGOT_PASSWORD,
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNT_TOKEN,
  UPDATE_CAREPROVIDER,
  UPDATE_CAREPROVIDER_ACCOUNT,
  UPDATE_CAREPROVIDER_ACCOUNT_TOKEN,
  UPDATE_CAREPROVIDER_TOKEN,
  UPDATE_CARESEEKER,
  UPDATE_PATIENT,
  UPDATE_REQUEST_SESSION_KEYS,
  UPDATE_SESSION_KEY,
} from "@recare/core/apollo/graphql";
import { useApolloEncryptionContext } from "@recare/core/model/patients/encryption/utils";
import { AnyObject, Careprovider, QueryProgress } from "@recare/core/types";
import { ReactNode, useCallback } from "react";
import {
  GenericUpdateMutation,
  toHoc,
  useUpdate,
  useUpdateWithId,
} from "./utils";
import { ApolloError } from "@apollo/client";

type QueryMutation = {
  children: (
    submit: (...args: any[]) => any,
    queryProgress: QueryProgress,
    resetProgress: () => void,
    error: ApolloError | undefined,
  ) => ReactNode | null;
  onCompleted?: (data: Readonly<any>) => void;
  onError?: (error: ApolloError | undefined) => void;
  onStarted?: () => void;
};

export const UpdateCareproviderMutation = ({
  careprovider,
  children,
  onCompleted,
  onError,
  onStarted,
  token,
}: QueryMutation & {
  careprovider: Careprovider;
  token?: string;
}) => (
  <GenericUpdateMutation
    getVariables={useUpdateWithId(careprovider?.id, token)}
    mutation={token ? UPDATE_CAREPROVIDER_TOKEN : UPDATE_CAREPROVIDER}
    onCompleted={onCompleted}
    onError={onError}
    onStarted={onStarted}
  >
    {children}
  </GenericUpdateMutation>
);

export const UpdateCareproviderMutationHOC = toHoc(
  UpdateCareproviderMutation,
  "onSubmitQueryProgress",
);

export const UpdateCareproviderAccountMutation = ({
  accountId,
  careproviderId,
  children,
  onCompleted,
  onError,
  onStarted,
  token,
}: QueryMutation & {
  accountId: number | null | undefined;
  careproviderId: number | null | undefined;
  token?: string;
}) => {
  const getVariables = (payload: AnyObject) => ({
    variables: {
      careproviderId,
      accountId,
      input: payload,
      token,
    },
  });
  return (
    <GenericUpdateMutation
      getVariables={getVariables}
      mutation={
        token ? UPDATE_CAREPROVIDER_ACCOUNT_TOKEN : UPDATE_CAREPROVIDER_ACCOUNT
      }
      onCompleted={onCompleted}
      onError={onError}
      onStarted={onStarted}
    >
      {children}
    </GenericUpdateMutation>
  );
};

export const UpdateCareseekerMutation = ({
  careseekerId,
  children,
  onCompleted,
  onError,
  onStarted,
}: QueryMutation & {
  careseekerId: number;
}) => (
  <GenericUpdateMutation
    getVariables={useUpdateWithId(careseekerId)}
    mutation={UPDATE_CARESEEKER}
    onCompleted={onCompleted}
    onError={onError}
    onStarted={onStarted}
  >
    {children}
  </GenericUpdateMutation>
);

export const CreateCareproviderMutation = ({
  children,
  onCompleted,
  onError,
  onStarted,
}: QueryMutation) => (
  <GenericUpdateMutation
    getVariables={useUpdate()}
    mutation={CREATE_CAREPROVIDER}
    onCompleted={onCompleted}
    onError={onError}
    onStarted={onStarted}
  >
    {children}
  </GenericUpdateMutation>
);

export const CreateCareproviderMutationHOC = toHoc(CreateCareproviderMutation);

export const UpdateAccountMutation = ({
  accountId,
  children,
  onCompleted,
  onError,
  onStarted,
  token,
}: QueryMutation & {
  accountId: number | null | undefined;
  token?: string;
}) => (
  <GenericUpdateMutation
    getVariables={useUpdateWithId(accountId || -1, token)}
    mutation={token ? UPDATE_ACCOUNT_TOKEN : UPDATE_ACCOUNT}
    onCompleted={onCompleted}
    onError={onError}
    onStarted={onStarted}
  >
    {children}
  </GenericUpdateMutation>
);

export const UpdateAccountMutationHOC = toHoc(
  UpdateAccountMutation,
  "onAccountUpdateQueryProgress",
);

export const UpdatePatientMutation = ({
  children,
  onCompleted,
  onError,
  onStarted,
  patientId,
}: QueryMutation & {
  patientId: number;
}) => {
  const encryptionContext = useApolloEncryptionContext();

  return (
    <GenericUpdateMutation
      getVariables={useUpdateWithId(patientId)}
      mutation={UPDATE_PATIENT}
      onCompleted={onCompleted}
      onError={onError}
      onStarted={onStarted}
      context={{ encryptionContext }}
    >
      {children}
    </GenericUpdateMutation>
  );
};

export const UpdatePatientMutationHOC = toHoc(UpdatePatientMutation);

/** @deprecated To be removed when old encrytion gets decommissioned */
export const UpdateRequestSessionKeysMutation = ({
  auctionRequestId,
  children,
  onCompleted,
  onError,
  onStarted,
}: QueryMutation & {
  auctionRequestId: number;
}) => {
  const getVariables = (newSessionKeys: AnyObject) => ({
    variables: {
      auctionRequestId,
      input: newSessionKeys,
    },
  });

  return (
    <GenericUpdateMutation
      getVariables={getVariables}
      mutation={UPDATE_REQUEST_SESSION_KEYS}
      onCompleted={onCompleted}
      onError={onError}
      onStarted={onStarted}
    >
      {children}
    </GenericUpdateMutation>
  );
};

export const UpdateSessionKeysMutation = ({
  children,
  onCompleted,
  onError,
  onStarted,
}: QueryMutation) => {
  const getVariables = (newSessionKeys: AnyObject) => ({
    variables: {
      input: newSessionKeys,
    },
  });

  return (
    <GenericUpdateMutation
      getVariables={getVariables}
      mutation={UPDATE_SESSION_KEY}
      onCompleted={onCompleted}
      onError={onError}
      onStarted={onStarted}
    >
      {children}
    </GenericUpdateMutation>
  );
};

export const CreatePendingSessionKeyMutation = ({
  accountId: account_id,
  auctionRequestId: auction_request_id,
  careproviderId: careprovider_id,
  careseekerId: careseeker_id,
  children,
  onCompleted,
  onError,
  onStarted,
}: QueryMutation & {
  accountId: number | undefined;
  auctionRequestId: number | undefined;
  careproviderId: number | undefined;
  careseekerId: number | undefined;
}) => {
  const getVariables = useCallback(
    () => ({
      variables: {
        input: {
          auction_request_id,
          careprovider_id,
          careseeker_id,
          account_id,
        },
      },
    }),
    [],
  );

  return (
    <GenericUpdateMutation
      getVariables={getVariables}
      mutation={CREATE_PENDING_SESSION_KEY}
      onCompleted={onCompleted}
      onError={onError}
      onStarted={onStarted}
    >
      {children}
    </GenericUpdateMutation>
  );
};

export const ForgotPasswordMutation = ({
  children,
  onCompleted,
  onError,
  onStarted,
}: QueryMutation) => {
  const getVariables = (payload: AnyObject) => ({
    variables: {
      endpoint: "reset/initiate",
      input: payload,
    },
  });

  return (
    <GenericUpdateMutation
      getVariables={getVariables}
      mutation={FORGOT_PASSWORD}
      onCompleted={onCompleted}
      onError={onError}
      onStarted={onStarted}
    >
      {children}
    </GenericUpdateMutation>
  );
};

export const AddCareproviderMemberMutation = ({
  careproviderId,
  children,
  invite = true,
  onCompleted,
  onError,
  onStarted,
  token,
}: QueryMutation & {
  careproviderId: number;
  invite?: boolean;
  token?: string;
}) => {
  const getVariables = (newAccount: AnyObject) => ({
    variables: {
      id: careproviderId,
      token,
      input: {
        ...newAccount,
      },
    },
  });
  return (
    <GenericUpdateMutation
      getVariables={getVariables}
      mutation={
        token
          ? ADD_CAREPROVIDER_MEMBER_TOKEN({ invite })
          : ADD_CAREPROVIDER_MEMBER({ invite })
      }
      onCompleted={onCompleted}
      onError={onError}
      onStarted={onStarted}
    >
      {children}
    </GenericUpdateMutation>
  );
};

export const CareproviderAccountAssociationMutation = ({
  careproviderId,
  children,
  onCompleted,
  onError,
  onStarted,
  token,
}: QueryMutation & {
  careproviderId: number;
  token?: string;
}) => {
  const getVariables = (newAccount: AnyObject) => ({
    variables: {
      id: careproviderId,
      token,
      input: {
        ...newAccount,
      },
    },
  });
  return (
    <GenericUpdateMutation
      getVariables={getVariables}
      mutation={CAREPROVIDER_ACCOUNT_ASSOCIATION}
      onCompleted={onCompleted}
      onError={onError}
      onStarted={onStarted}
    >
      {children}
    </GenericUpdateMutation>
  );
};

export const CreateAccountsBatchMutation = ({
  children,
  onCompleted,
  onError,
  onStarted,
}: QueryMutation) => (
  <GenericUpdateMutation
    getVariables={useUpdate()}
    mutation={CREATE_ACCOUNTS_BATCH}
    onCompleted={onCompleted}
    onError={onError}
    onStarted={onStarted}
    refetchQueries={["getAccounts"]}
  >
    {children}
  </GenericUpdateMutation>
);

export const BatchAddProviderAccountsMutation = ({
  careproviderId,
  children,
  onCompleted,
  onError,
  onStarted,
}: QueryMutation & {
  careproviderId: number;
}) => {
  const getVariables = useCallback(
    (input: any) => ({
      variables: {
        id: careproviderId,
        input,
      },
    }),
    [],
  );

  return (
    <GenericUpdateMutation
      getVariables={getVariables}
      mutation={BATCH_ADD_PROVIDER_ACCOUNTS}
      onCompleted={onCompleted}
      onError={onError}
      onStarted={onStarted}
    >
      {children}
    </GenericUpdateMutation>
  );
};
