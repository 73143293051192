import {
  FACILITY_ASSISTED_LIVING,
  FACILITY_COMPANIONSHIP,
  FACILITY_DAILY_LIVING_SUPPORT_SERVICES,
  FACILITY_DAY_CARE,
  FACILITY_DAY_HOSPITALIZATION,
  FACILITY_FULLTIME_COMPANIONSHIP,
  FACILITY_FULLTIME_MOBILE_CARE,
  FACILITY_FULL_HOSPITALIZATION,
  FACILITY_HOME_CARE,
  FACILITY_HOSPICE_ASSOCIATION,
  FACILITY_HOSPICE_MOBILE,
  FACILITY_HOSPICE_PARTIALLY_MOBILE,
  FACILITY_HOSPICE_STATIC,
  FACILITY_KTW_CARRY_CHAIR,
  FACILITY_KTW_LYING_DOWN,
  FACILITY_KTW_MEDICAL_CARE,
  FACILITY_KTW_WHEELCHAIR,
  FACILITY_MEDICAL_SUPPLIES,
  FACILITY_MOBILE_CARE,
  FACILITY_NIGHT_CARE_MOBILE,
  FACILITY_NIGHT_CARE_STATIC,
  FACILITY_REHAB_DAY_HOSPITALIZATION,
  FACILITY_REHAB_FULL_HOSPITALIZATION,
  FACILITY_REHAB_MOBILE,
  FACILITY_RENTAL_CAR_LYING_DOWN,
  FACILITY_RENTAL_CAR_WHEELCHAIR,
  FACILITY_SHARED_FLAT,
  FACILITY_SHORT_TERM_CARE,
  FACILITY_STATIC_CARE,
  FACILITY_TAXI,
  FACILITY_TAXI_CARRY_CHAIR,
  SOLUTION_ASSISTED_LIVING,
  SOLUTION_DAILY_LIVING_SUPPORT_SERVICES,
  SOLUTION_DAY_CARE,
  SOLUTION_DAY_HOSPITALIZATION,
  SOLUTION_FULL_HOSPITALIZATION,
  SOLUTION_FULL_TIME_MOBILE_CARE,
  SOLUTION_HELP_AT_HOME,
  SOLUTION_HOME_CARE,
  SOLUTION_HOSPICE_ASSOCIATION,
  SOLUTION_HOSPICE_MOBILE,
  SOLUTION_HOSPICE_PARTIALLY_MOBILE,
  SOLUTION_HOSPICE_STATIC,
  SOLUTION_KTW_CARRY_CHAIR,
  SOLUTION_KTW_LYING_DOWN,
  SOLUTION_KTW_WHEELCHAIR,
  SOLUTION_KTW_WITH_MEDICAL_CARE,
  SOLUTION_MEDICAL_SUPPLIES,
  SOLUTION_MOBILE_CARE,
  SOLUTION_NIGHT_CARE_MOBILE,
  SOLUTION_NIGHT_CARE_STATIC,
  SOLUTION_REHAB_DAY_HOSPITALIZATION,
  SOLUTION_REHAB_FULL_HOSPITALIZATION,
  SOLUTION_REHAB_MOBILE,
  SOLUTION_RENTAL_CAR_CARRY_CHAIR,
  SOLUTION_RENTAL_CAR_LYING_DOWN,
  SOLUTION_RENTAL_CAR_WHEELCHAIR,
  SOLUTION_SHARED_FLAT,
  SOLUTION_SHORT_TERM_STATIC_CARE,
  SOLUTION_STATIC_CARE,
  SOLUTION_TAXI,
} from "@recare/core/consts";
import "core-js/features/array/flat";

const solutionFacilitiesMap: { [key: number]: Array<number> } = {
  [SOLUTION_SHARED_FLAT]: [FACILITY_SHARED_FLAT],
  [SOLUTION_HELP_AT_HOME]: [FACILITY_COMPANIONSHIP],
  [SOLUTION_MOBILE_CARE]: [FACILITY_MOBILE_CARE],
  [SOLUTION_FULL_TIME_MOBILE_CARE]: [
    FACILITY_FULLTIME_MOBILE_CARE,
    FACILITY_FULLTIME_COMPANIONSHIP,
  ],
  [SOLUTION_DAY_CARE]: [FACILITY_DAY_CARE],
  [SOLUTION_SHORT_TERM_STATIC_CARE]: [FACILITY_SHORT_TERM_CARE],
  [SOLUTION_STATIC_CARE]: [FACILITY_STATIC_CARE],
  [SOLUTION_DAY_HOSPITALIZATION]: [FACILITY_DAY_HOSPITALIZATION],
  [SOLUTION_FULL_HOSPITALIZATION]: [FACILITY_FULL_HOSPITALIZATION],
  [SOLUTION_REHAB_FULL_HOSPITALIZATION]: [FACILITY_REHAB_FULL_HOSPITALIZATION],
  [SOLUTION_REHAB_DAY_HOSPITALIZATION]: [FACILITY_REHAB_DAY_HOSPITALIZATION],
  [SOLUTION_REHAB_MOBILE]: [FACILITY_REHAB_MOBILE],
  [SOLUTION_TAXI]: [FACILITY_TAXI],
  [SOLUTION_RENTAL_CAR_WHEELCHAIR]: [FACILITY_RENTAL_CAR_WHEELCHAIR],
  [SOLUTION_RENTAL_CAR_CARRY_CHAIR]: [FACILITY_TAXI_CARRY_CHAIR],
  [SOLUTION_RENTAL_CAR_LYING_DOWN]: [FACILITY_RENTAL_CAR_LYING_DOWN],
  [SOLUTION_KTW_WITH_MEDICAL_CARE]: [FACILITY_KTW_MEDICAL_CARE],
  [SOLUTION_MEDICAL_SUPPLIES]: [FACILITY_MEDICAL_SUPPLIES],
  [SOLUTION_HOME_CARE]: [FACILITY_HOME_CARE],
  [SOLUTION_KTW_WHEELCHAIR]: [FACILITY_KTW_WHEELCHAIR],
  [SOLUTION_KTW_CARRY_CHAIR]: [FACILITY_KTW_CARRY_CHAIR],
  [SOLUTION_KTW_LYING_DOWN]: [FACILITY_KTW_LYING_DOWN],
  [SOLUTION_HOSPICE_ASSOCIATION]: [FACILITY_HOSPICE_ASSOCIATION],
  [SOLUTION_HOSPICE_STATIC]: [FACILITY_HOSPICE_STATIC],
  [SOLUTION_HOSPICE_PARTIALLY_MOBILE]: [FACILITY_HOSPICE_PARTIALLY_MOBILE],
  [SOLUTION_HOSPICE_MOBILE]: [FACILITY_HOSPICE_MOBILE],
  [SOLUTION_NIGHT_CARE_STATIC]: [FACILITY_NIGHT_CARE_STATIC],
  [SOLUTION_NIGHT_CARE_MOBILE]: [FACILITY_NIGHT_CARE_MOBILE],
  [SOLUTION_ASSISTED_LIVING]: [FACILITY_ASSISTED_LIVING],
  [SOLUTION_DAILY_LIVING_SUPPORT_SERVICES]: [
    FACILITY_DAILY_LIVING_SUPPORT_SERVICES,
  ],
};

export function facilityToSolution(facility: number): number {
  const mappedSolution =
    Object.entries(solutionFacilitiesMap).find(([, facilities]) =>
      facilities.includes(facility),
    ) || [];
  return parseInt(mappedSolution[0] || "");
}

export function solutionToFacilities(solution: number): Array<number> {
  return solutionFacilitiesMap[solution] || [];
}

export function solutionsToFacilities(solutions: Array<number>): Array<number> {
  return Array.from(
    new Set(solutions.flatMap((solution) => solutionToFacilities(solution))),
  );
}

export function isMobile(solution: number | null | undefined): boolean {
  return (
    solution === SOLUTION_MOBILE_CARE ||
    solution === SOLUTION_FULL_TIME_MOBILE_CARE ||
    solution === SOLUTION_HELP_AT_HOME
  );
}

export function hasMobile(solutions: number[] | null | undefined): boolean {
  return !!solutions?.some(isMobile);
}
