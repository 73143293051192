import { BarthelIndex, RehabIndex } from "@recare/core/types";
import Translations from "@recare/translations/types";

export type RehabElement = {
  elementName: keyof RehabIndex["form"];
  translation: string;
  value: number | null | undefined;
};

export type BarthelElement = {
  elementName: keyof BarthelIndex["form"];
  title: string;
  values: {
    label: string;
    value: number;
  }[];
};

export const getRehabElements = (
  translations: Translations,
): RehabElement[] => {
  return [
    {
      translation:
        translations.patient.medicalDiagnosis.earlyRehabIndex
          .intensiveMonitoring,
      elementName: "intensive_medical_monitoring",
      value: -50,
    },

    {
      translation:
        translations.patient.medicalDiagnosis.earlyRehabIndex.tracheostoma,
      elementName: "tracheostoma_requiring_aspiration",
      value: -50,
    },
    {
      translation:
        translations.patient.medicalDiagnosis.earlyRehabIndex
          .intermittentVentilation,
      elementName: "intermittent_ventilation",
      value: -50,
    },

    {
      translation:
        translations.patient.medicalDiagnosis.earlyRehabIndex
          .orientationConfusion,
      elementName: "orientation_disorder",
      value: -50,
    },
    {
      translation:
        translations.patient.medicalDiagnosis.earlyRehabIndex
          .behaviouralDisorder,
      elementName: "behavioural_disorder",
      value: -50,
    },
    {
      translation:
        translations.patient.medicalDiagnosis.earlyRehabIndex
          .communicationDisorder,
      elementName: "severe_communication_disorder",
      value: -25,
    },
    {
      translation:
        translations.patient.medicalDiagnosis.earlyRehabIndex
          .swallowingDisorder,
      elementName: "supervised_swallowing_disorder",
      value: -50,
    },
  ];
};

export const getBarthelElements = (
  translations: Translations,
): BarthelElement[] => [
  {
    elementName: "food",
    title: translations.acp.barthelIndex.food,
    values: [
      {
        value: 10,
        label: translations.acp.barthelIndex.foodValueTen,
      },
      {
        value: 5,
        label: translations.acp.barthelIndex.foodValueFive,
      },
      {
        value: 0,
        label: translations.acp.barthelIndex.foodValueZero,
      },
    ],
  },

  {
    elementName: "setting_up_and_relocating",
    title: translations.acp.barthelIndex.relocating,
    values: [
      {
        value: 15,
        label: translations.acp.barthelIndex.relocatingValueFifteen,
      },
      {
        value: 10,
        label: translations.acp.barthelIndex.relocatingValueTen,
      },
      {
        value: 5,
        label: translations.acp.barthelIndex.relocatingFive,
      },
      {
        value: 0,
        label: translations.acp.barthelIndex.relocatingZero,
      },
    ],
  },
  {
    elementName: "personal_care",
    title: translations.acp.barthelIndex.personalCare,
    values: [
      {
        value: 5,
        label: translations.acp.barthelIndex.personalCareValueFive,
      },
      { value: 0, label: translations.acp.barthelIndex.valueZero },
    ],
  },
  {
    elementName: "toilet_use",
    title: translations.acp.barthelIndex.toiletUse,
    values: [
      {
        value: 10,
        label: translations.acp.barthelIndex.toiletUseValueTen,
      },
      {
        value: 5,
        label: translations.acp.barthelIndex.toiletUseValueFive,
      },
      {
        value: 0,
        label: translations.acp.barthelIndex.toiletUseValueZero,
      },
    ],
  },
  {
    elementName: "bathing",
    title: translations.acp.barthelIndex.bathing,
    values: [
      {
        value: 5,
        label: translations.acp.barthelIndex.bathingValueFive,
      },
      {
        value: 0,
        label: translations.acp.barthelIndex.valueZero,
      },
    ],
  },
  {
    elementName: "getting_up_and_walking",
    title: translations.acp.barthelIndex.deplacement,
    values: [
      {
        value: 15,
        label: translations.acp.barthelIndex.deplacementValueFifteen,
      },
      {
        value: 10,
        label: translations.acp.barthelIndex.deplacementValueTen,
      },
      {
        value: 5,
        label: translations.acp.barthelIndex.deplacementValueFive,
      },
      {
        value: 0,
        label: translations.acp.barthelIndex.valueZero,
      },
    ],
  },
  {
    elementName: "stair_climbing",
    title: translations.acp.barthelIndex.stairClimbing,
    values: [
      {
        value: 10,
        label: translations.acp.barthelIndex.stairClimbingValueTen,
      },
      {
        value: 5,
        label: translations.acp.barthelIndex.stairClimbingValueFive,
      },
      {
        value: 0,
        label: translations.acp.barthelIndex.valueZero,
      },
    ],
  },
  {
    elementName: "dressing_and_undressing",
    title: translations.acp.barthelIndex.dressing,
    values: [
      {
        value: 10,
        label: translations.acp.barthelIndex.dressingValueTen,
      },
      {
        value: 5,
        label: translations.acp.barthelIndex.dressingValueFive,
      },
      {
        value: 0,
        label: translations.acp.barthelIndex.valueZero,
      },
    ],
  },
  {
    elementName: "fecal_incontinence",
    title: translations.acp.barthelIndex.fecalIncontinence,
    values: [
      {
        value: 10,
        label: translations.acp.barthelIndex.fecalIncontinenceValueTen,
      },
      {
        value: 5,
        label: translations.acp.barthelIndex.fecalIncontinenceValueFive,
      },
      {
        value: 0,
        label: translations.acp.barthelIndex.fecalIncontinenceValueZero,
      },
    ],
  },
  {
    elementName: "urinary_incontinence",
    title: translations.acp.barthelIndex.uninaryIncontinence,
    values: [
      {
        value: 10,
        label: translations.acp.barthelIndex.uninaryIncontinenceValueTen,
      },
      {
        value: 5,
        label: translations.acp.barthelIndex.uninaryIncontinenceValueFive,
      },
      {
        value: 0,
        label: translations.acp.barthelIndex.uninaryIncontinenceValueZero,
      },
    ],
  },
];
