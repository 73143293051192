import CheckCircle from "@mui/icons-material/CheckCircle";
import {
  QUERY_PROGRESS_PENDING,
  QUERY_PROGRESS_SUCCEED,
} from "@recare/core/consts";
import { QueryProgress } from "@recare/core/types";
import Translations from "@recare/translations/types";
import React from "react";
import RSButton from "./index";

export default function RSButtonSaveProgress({
  disabled,
  onSubmit,
  queryProgress,
  style,
  translations,
}: {
  disabled?: boolean;
  onSubmit: AnyFunction;
  queryProgress: QueryProgress;
  style?: React.CSSProperties;
  translations: Translations;
}) {
  switch (queryProgress) {
    case QUERY_PROGRESS_PENDING:
      return (
        <RSButton
          color="secondary"
          id="saving"
          loading="na"
          onClick={onSubmit}
          style={style}
          variant="contained"
        >
          <span style={{ width: "100%" }}>{translations.actions.saving}</span>
        </RSButton>
      );
    case QUERY_PROGRESS_SUCCEED:
      return (
        <RSButton
          color="secondary"
          id="saved"
          LeftIcon={CheckCircle}
          loading="na"
          onClick={onSubmit}
          style={style}
          variant="contained"
        >
          <span style={{ width: "100%" }}>{translations.actions.saved}</span>
        </RSButton>
      );
    default:
      return (
        <RSButton
          color="secondary"
          disabled={disabled}
          id="save"
          loading="na"
          onClick={onSubmit}
          style={style}
          variant="contained"
        >
          <span style={{ width: "100%" }}>{translations.actions.save}</span>
        </RSButton>
      );
  }
}
