import { formatUnixDate } from "@recare/core/model/utils/dates";
import { Patient, RehabIndex } from "@recare/core/types";
import { useLocale, useTranslations } from "@recare/translations";
import { INFO_CARD_BORDER_BOTTOM } from "ds/materials/colors";
import { dp, margin } from "ds/materials/metrics";
import { Body, Subheading } from "ds/materials/typography";
import { usePrintWindow } from "dsl/atoms/PrintButton";
import { useOnEnter } from "dsl/atoms/Routes";
import { useScrollToTop } from "dsl/hooks";
import { Fragment } from "react";
import { RehabElement, getRehabElements } from "./elements";
import { BarthelTitle, ReturnBar, sumValues } from "./shared";
import {
  BarthelDividerRow,
  BarthelTable,
  BarthelTableCell,
  BarthelTableRow,
  BarthelWrapper,
  TobBarWrapper,
} from "./styles";

function getElementValue(list: RehabIndex, rehabElement: RehabElement) {
  return list?.form?.[rehabElement.elementName];
}

function Divider({ columnsNumber }: { columnsNumber: number }) {
  const columns = Array.from({ length: columnsNumber + 1 }).map((_, i) => i);
  return (
    <BarthelDividerRow>
      {columns.map((col) => (
        <BarthelTableCell key={col} />
      ))}
    </BarthelDividerRow>
  );
}

export function BarthelSummary({
  earlyBarthelIndexList,
  patient,
}: {
  earlyBarthelIndexList: RehabIndex[];
  patient?: Patient;
}) {
  const translations = useTranslations();
  const locale = useLocale();
  const elements = getRehabElements(translations);
  if (!earlyBarthelIndexList.length) return null;

  return (
    <BarthelWrapper>
      <BarthelTable>
        <tbody>
          <tr>
            <BarthelTableCell noBorder style={{ height: dp(50) }}>
              <BarthelTitle
                translations={translations}
                patient={patient}
                print
              />
            </BarthelTableCell>
            {earlyBarthelIndexList.map((list) => (
              <BarthelTableCell
                key={JSON.stringify(list.date)}
                textAlign="center"
                noBorder
                style={{ height: dp(50) }}
              >
                <Body fontWeight="bold">
                  {formatUnixDate(list.date, locale)}
                </Body>
              </BarthelTableCell>
            ))}
          </tr>
          <tr>
            <BarthelTableCell />
            {earlyBarthelIndexList.map((list) => (
              <BarthelTableCell
                key={JSON.stringify(list.date)}
                textAlign="center"
              >
                <Body
                  data-testid="resultWrapper"
                  margin={margin(0, 2, 2, 2)}
                  fontWeight="bold"
                  fontSize={dp(20)}
                >
                  = {sumValues(list.form)}
                </Body>
              </BarthelTableCell>
            ))}
          </tr>
          {elements.map((rehabElement: RehabElement, index: number) => (
            <Fragment key={rehabElement.elementName}>
              {index !== 0 && (
                <>
                  <BarthelTableRow>
                    <BarthelTableCell>
                      <Subheading> </Subheading>
                    </BarthelTableCell>
                    {earlyBarthelIndexList.map((list) => (
                      <BarthelTableCell key={JSON.stringify(list.date)} />
                    ))}
                  </BarthelTableRow>
                </>
              )}

              <BarthelTableRow>
                {earlyBarthelIndexList.map((list) => (
                  <BarthelTableCell key={JSON.stringify(list.date)} />
                ))}
              </BarthelTableRow>
              <>
                <BarthelTableRow key={JSON.stringify(rehabElement)}>
                  <BarthelTableCell borderRight={INFO_CARD_BORDER_BOTTOM}>
                    <Body>{rehabElement.translation}</Body>
                  </BarthelTableCell>
                  {earlyBarthelIndexList.map((list) => {
                    const checked =
                      getElementValue(list, rehabElement) ===
                      rehabElement.value;
                    return (
                      <BarthelTableCell
                        key={JSON.stringify(list)}
                        textAlign="center"
                      >
                        <Body data-testid={checked ? "checked" : undefined}>
                          {checked ? "x" : ""}
                        </Body>
                      </BarthelTableCell>
                    );
                  })}
                </BarthelTableRow>
                {elements.length - 1 !== index && (
                  <Divider columnsNumber={earlyBarthelIndexList.length} />
                )}
              </>
            </Fragment>
          ))}
        </tbody>
      </BarthelTable>
    </BarthelWrapper>
  );
}

export default function SummaryPage({
  auctionId,
  patient,
}: {
  auctionId: number;
  patient: Patient;
}) {
  useOnEnter();
  const translations = useTranslations();
  const earlyBarthelIndexList =
    patient?.diagnosis?.dependency_diagnosis?.early_rehab_barthel_index_list ||
    [];

  usePrintWindow();
  useScrollToTop();

  const sortedEarlyBarthelIndexList = earlyBarthelIndexList.sort(
    (a, b) => a.date - b.date,
  );
  return (
    <>
      <ReturnBar patient={patient} auctionId={auctionId} />
      <TobBarWrapper>
        <BarthelTitle
          translations={translations}
          patient={patient}
          earlyRehab
        />
      </TobBarWrapper>
      <BarthelSummary
        patient={patient}
        earlyBarthelIndexList={sortedEarlyBarthelIndexList}
      />
    </>
  );
}
