import { getStaticAsset } from "@recare/core/model/config";
import React, { forwardRef } from "react";

type BaseImageProps = {
  alt: string;
  onClick?: () => void; // #TODO: We should use a router link (Link or NavLink) when navigating to an internal url (within the app) and a regular tag when navigating to an external url for accessibility and for clear semantic purposes.
  style?: React.CSSProperties;
  testId?: string;
  width?: React.CSSProperties["width"];
};

type ImagePropsWithSrc = BaseImageProps & {
  path?: never;
  src: string;
};

type ImagePropsWithPath = BaseImageProps & {
  path: string;
  src?: never;
};

export const Image = forwardRef<
  HTMLImageElement,
  ImagePropsWithSrc | ImagePropsWithPath
>(({ alt, onClick, path, src, style, testId, width }, ref) => {
  const imageUrl = src ? src : getStaticAsset(path as string);

  return (
    <img
      alt={alt}
      data-testid={testId}
      onClick={onClick}
      ref={ref}
      src={imageUrl}
      style={{ ...style, objectFit: "contain" }}
      width={width}
    />
  );
});

export default Image;
