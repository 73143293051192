import { ApolloClient } from "@apollo/client";
import {
  encryptionContext as encryptionContextFragment,
  requestActions,
} from "@recare/core/apollo/graphql/fragments/fragments";
import { REQUEST_ACTION_ACCESS_TO_DATA } from "@recare/core/consts";
import {
  ApolloCacheData,
  AuctionRequest,
  EncryptionContext,
  PendingSealdAccess,
  RequestActionTypes,
} from "@recare/core/types";
import { useUpdateAccount } from "apollo/hooks/mutations";
import gql from "graphql-tag";
import { capitalize } from "lodash";
import { EntityType, computeIdFromSealdDisplayId } from ".";

// TODO: move utils from index

export type UpdateSealdRegistered =
  | ReturnType<typeof useUpdateAccount>[0]
  | null;

export const getSealdEntityFromPayload = (
  payload: EncryptionContext | PendingSealdAccess,
) => {
  const entity = Object.entries(payload)
    .filter(
      ([key, value]) =>
        key.includes("_id") && !key.includes("seald") && value != null,
    )
    .map(([key, value]) => [key.replace("_id", ""), value])
    .pop();
  if (!entity) throw new Error("Malformed PSK");
  const [entityType, entityId] = entity;
  return [entityType, entityId] as [EntityType, number];
};

export const getIdsFromSealdDisplayIds = (
  entityUsersDisplayIds: Array<string>,
) =>
  entityUsersDisplayIds.map((userDisplayId) => {
    const [userType, id] = computeIdFromSealdDisplayId({
      displayId: userDisplayId,
    });
    return [`${userType}_id`, id] as const;
  });

export function updateSealdContextInApolloCache({
  apolloClient,
  encryptionContext,
  entityType,
  id,
}: {
  apolloClient: ApolloClient<ApolloCacheData>;
  encryptionContext: EncryptionContext;
  entityType: string;
  id: number;
}) {
  const normalizedEntity = entityType
    // e.g. auction_request
    .split("_")
    .map(capitalize)
    // AuctionRequest
    .join("");
  apolloClient.writeQuery({
    query: gql`
            query ${normalizedEntity} {
              ${normalizedEntity} {
                id
                has_seald_encryption_context
                seald_encryption_context {
                  ${encryptionContextFragment()}
                }
              }
            }
          `,
    data: {
      [normalizedEntity]: {
        id,
        has_seald_encryption_context: true,
        seald_encryption_context: encryptionContext,
        __typename: normalizedEntity,
      },
    },
  });
}

export function optimisticallySetShareActionInApolloCache({
  apolloClient,
  auctionRequest,
}: {
  apolloClient: ApolloClient<ApolloCacheData>;
  auctionRequest: AuctionRequest;
}) {
  apolloClient.writeQuery({
    query: gql`
            query AuctionRequest {
              AuctionRequest {
                id
                ${requestActions}
              }
            }
          `,
    data: {
      AuctionRequest: {
        id: auctionRequest.id,
        request_actions: auctionRequest.request_actions?.filter(
          ({ action_type }: RequestActionTypes) =>
            action_type !== REQUEST_ACTION_ACCESS_TO_DATA,
        ),
        __typename: "AuctionRequest",
      },
    },
  });
}
