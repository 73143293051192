import {
  COLUMN_TYPE_FILES_CATEGORY,
  COLUMN_TYPE_FILES_FILE_NAME,
  QUERY_PROGRESS_PENDING,
  TRACK_EVENTS,
} from "@recare/core/consts";
import { FileTableActions, Filev2, QueryProgress } from "@recare/core/types";
import { useTranslations } from "@recare/translations";
import GeneralTable from "ds/components/Tables/GeneralTable";
import { COLUMN_TYPE_DEFAULT_DATE } from "ds/components/Tables/GeneralTable/CustomCells";
import { EmptyV2 } from "ds/components/Tables/GeneralTable/EmptyComponent";
import { SetAction } from "ds/components/Tables/GeneralTable/MenuActions";
import {
  getAllowList,
  getFileListActionOptions,
} from "ds/components/Tables/GeneralTable/MenuActions/Files";
import { VerticalLayout } from "ds/materials/layouts";
import { FileFeatureData } from "dsl/ecosystems/PatientFiles/useFilesFeature";
import { getFileName } from "dsl/ecosystems/PatientFiles/utils";
import {
  EncryptedFileMessage,
  FilesTableTitle,
} from "dsl/organisms/FileTables";
import { useTracking } from "react-tracking";

export type SentFilesProps = {
  canSendFiles?: boolean;
  files?: Filev2[] | null;
  isMessenger?: boolean;
  queryProgress: QueryProgress;
  setAction: SetAction<FileFeatureData>;
};

export function SentFiles({
  canSendFiles = true,
  files,
  isMessenger,
  queryProgress,
  setAction,
}: SentFilesProps) {
  const translations = useTranslations();
  const { trackEvent } = useTracking();

  return (
    <VerticalLayout>
      <FilesTableTitle
        description={isMessenger ? <EncryptedFileMessage /> : ""}
        isMessenger={isMessenger}
        numberOfFiles={files?.length ?? 0}
        title={translations.fileSection.fileListComponent.titleFilesSent}
      />

      <GeneralTable<Filev2, FileTableActions>
        id="sent-files"
        isLoading={queryProgress === QUERY_PROGRESS_PENDING}
        data={files || []}
        columns={[
          {
            getProps: "category",
            title: translations.fileSection.fileListComponent.category,
            type: COLUMN_TYPE_FILES_CATEGORY,
            width: "5%",
          },
          {
            getProps: (file) =>
              getFileName({ file, canAccessPatientData: true }),
            title: translations.fileSection.fileListComponent.fileName,
            type: COLUMN_TYPE_FILES_FILE_NAME,
            width: isMessenger ? "45%" : "30%",
          },
          {
            getProps: ({ created_at }) => ({
              value: created_at,
            }),
            title: translations.fileSection.fileListComponent.shareDate,
            type: COLUMN_TYPE_DEFAULT_DATE,
          },
        ]}
        actions={{
          getOptions: ({ data: file, translations }) =>
            getFileListActionOptions({
              canAccessPatientData: true,
              file,
              fileTable: "sent",
              setAction,
              trackEvent,
              translations,
            }),
          allowList: () => getAllowList({ fileTable: "sent" }),
          getTestId: (data) => `action-sent-file-${data.id}`,
          onActionMenuClick: () => {
            trackEvent({
              name: TRACK_EVENTS.FILE_MENU_CLICKED,
              section: "sent",
              file_implementation: "HIS",
            });
          },
        }}
        emptyComponent={
          <EmptyV2
            isMessenger={isMessenger}
            title={
              canSendFiles
                ? translations.fileSection.fileListComponent
                    .placeholderNothingSent
                : translations.messenger.receiver.fileRestrictionHint
            }
          />
        }
      />
    </VerticalLayout>
  );
}

export default SentFiles;
