import { ALGOLIA_TRACK_EVENTS } from "@recare/core/consts";
import { useEnvContext } from "context/EnvContext";
import { useEffect } from "react";
import { useLoggedInAccount } from "reduxentities/selectors/hooks";
import insights from "search-insights";
import { InsightsSearchClickEvent } from "search-insights/dist/click";
import { InsightsSearchConversionEvent } from "search-insights/dist/conversion";

export const SESSION_STORAGE_KEY_SEARCH_INSIGHTS = "search-insights";

export function useOnClickAlgoliaItem() {
  const account = useLoggedInAccount();

  const onClickAlgoliaItem = ({
    index,
    objectIDs,
    positions,
    queryID,
  }: Pick<
    InsightsSearchClickEvent,
    "objectIDs" | "positions" | "queryID" | "index"
  >) => {
    if (!objectIDs.length) {
      console.error("[onClickAlgoliaItem] No objectIDS");
    }
    if (!queryID) {
      console.error("[onClickAlgoliaItem] No queryID");
    }
    if (!account?.id) {
      console.error("[convertAlgoliaEventAfterSearch] No account ID");
    }

    insights("clickedObjectIDsAfterSearch", {
      eventName: ALGOLIA_TRACK_EVENTS.SEARCH_RESULT_CLICKED,
      index,
      objectIDs,
      positions,
      queryID,
      userToken: `${account?.id ?? -1}`,
    });

    sessionStorage.setItem(
      SESSION_STORAGE_KEY_SEARCH_INSIGHTS,
      JSON.stringify({ queryID, objectIDs }),
    );
  };
  return { onClickAlgoliaItem };
}

export function useConvertAlgoliaEventAfterSearch() {
  const account = useLoggedInAccount();

  const convertAlgoliaEventAfterSearch = ({
    eventName,
    index,
  }: Pick<InsightsSearchConversionEvent, "index" | "eventName">) => {
    try {
      const stringifiedValue = sessionStorage.getItem(
        SESSION_STORAGE_KEY_SEARCH_INSIGHTS,
      );
      const parsedValue = JSON.parse(stringifiedValue as string);

      if (!parsedValue) {
        console.error("[convertAlgoliaEventAfterSearch] parsedValue is null");
        return;
      }

      const { objectIDs, queryID } = parsedValue;

      if (!objectIDs.length) {
        console.error("[convertAlgoliaEventAfterSearch] No objectIDS");
      }
      if (!queryID) {
        console.error("[convertAlgoliaEventAfterSearch] No queryID");
      }
      if (!account?.id) {
        console.error("[convertAlgoliaEventAfterSearch] No account ID");
      }

      insights("convertedObjectIDsAfterSearch", {
        index,
        eventName,
        queryID,
        objectIDs,
        userToken: `${account?.id ?? -1}`,
      });

      sessionStorage.removeItem(SESSION_STORAGE_KEY_SEARCH_INSIGHTS);
    } catch (err) {
      console.error(`[convertAlgoliaEventAfterSearch] ${err}`);
    }
  };
  return { convertAlgoliaEventAfterSearch };
}

export function useSearchInsights() {
  const { config } = useEnvContext();

  useEffect(() => {
    insights("init", {
      appId: config?.algolia.appId,
      apiKey: config?.algolia.apiKey,
    });
  }, []);
}
