import { useApolloClient } from "@apollo/client";
import { CAREPROVIDER_TAB_DEFAULT, REDIRECT_PARAM } from "@recare/core/consts";
import { hasAccessToReverseSearch } from "@recare/core/model/careproviders";
import {
  activateSingerAssessment,
  isProd,
} from "@recare/core/model/utils/featureFlags";
import {
  getQueryIntVariable,
  getQueryVariable,
  useIntParams,
} from "@recare/core/model/utils/urls";
import { Careprovider, CareproviderTab } from "@recare/core/types";
import { useGetCareprovider } from "apollo/hooks/queries";
import { Result } from "apollo/utils";
import { PatientFilesProvider } from "containers/PatientFilesProvider";
import RootPage from "containers/RootPage";
import { useCareproviderAccountContext } from "context/CareproviderAccountContext";
import { SpinnerPage } from "ds/components/Spinner";
import { useAllowPWA } from "dsl/atoms/BrowserNotificationContext/NotificationPermission";
import MagicLogin from "dsl/atoms/MagicLogin/lazy";
import RedirectRoot from "dsl/atoms/RedirectRoot";
import ThrowError from "dsl/atoms/ThrowError";
import lazy from "dsl/atoms/retry";
import AuctionRequestHome from "dsl/ecosystems/AuctionRequest/ConnectAuctionRequest";
import {
  ConnectedBarthelIndex,
  ConnectedEarlyRehabIndex,
} from "dsl/ecosystems/AuctionRequest/ConnectBarthelIndex";
import {
  clearUpdatedRequest,
  getUpdatedRequest,
} from "dsl/ecosystems/AuctionRequest/updatedRequest";
import CareproviderAuthPage from "dsl/ecosystems/CareproviderAuthPage";
import LogoutPage from "dsl/ecosystems/LogoutPage";
import { useScrollRestoreCheckLocation } from "dsl/hooks/useScrollRestoreCheckLocation";
import CareproviderAccountsProvider from "dsl/organisms/CareproviderAccountsProvider";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { selectLoggedCareproviderId } from "reduxentities/selectors";
import { useLoggedCareproviderId } from "reduxentities/selectors/hooks";
import { ReceiverContext } from "..";
import {
  DebugPage,
  RequireCondition,
  ValidateCareprovider,
  getDefaultRedirectPath,
} from "./helpers";

const DiagnosticPage = lazy(() => import("dsl/organisms/DiagnosticPage"));
const TestSeald = lazy(() => import("dsl/atoms/TestSeald"));
const PasswordResetPage = lazy(() => import("dsl/organisms/PasswordResetPage"));
const PasswordChangePage = lazy(
  () => import("dsl/organisms/PasswordChangePage"),
);
const ReceiverDashboard = lazy(() => import("dsl/organisms/ReceiverDashboard"));

const CareproviderDashboard = lazy(
  () => import("dsl/ecosystems/CareproviderDashboard"),
);
const lazyPreload = (importStatement: AnyFunction) => {
  const Component: any = lazy(importStatement);
  Component.preload = importStatement;
  return Component;
};
export const AuctionRequest = lazyPreload(
  () => import("dsl/ecosystems/AuctionRequest/connect"),
);
const SingerAssessmentSummaryPage = lazy(
  () => import("dsl/ecosystems/CareproviderSingerAssessmentSummaryPage"),
);
const SettingsAccountPage = lazy(
  () => import("dsl/ecosystems/CareproviderSettingsAccount"),
);
const SettingsCompanyProfilePage = lazy(
  () => import("dsl/ecosystems/CareproviderSettingsCompanyProfile"),
);
const SettingsServicesPage = lazy(
  () => import("dsl/ecosystems/CareproviderSettingsServices"),
);
const SettingsTeamPage = lazy(
  () => import("dsl/ecosystems/CareproviderSettingsTeam"),
);
const SettingsNotificationsPage = lazy(
  () => import("dsl/ecosystems/CareproviderSettingsNotifications"),
);
const SettingsPWAPage = lazy(
  () => import("dsl/ecosystems/CareproviderSettingsPWA"),
);
const SettingsPartnerClinicsPage = lazy(
  () => import("dsl/ecosystems/CareproviderSettingsPartnerClinics"),
);
const SettingsLegalDocumentsPage = lazy(
  () => import("dsl/ecosystems/CareproviderSettingsLegalDocuments"),
);
const SettingsExtensionsPage = lazy(
  () => import("dsl/ecosystems/CareproviderSettingsExtensions"),
);
const SettingsCapacityPage = lazy(
  () => import("dsl/ecosystems/CareproviderSettingsCapacity/index"),
);
const SettingsReverseSearchPage = lazy(
  () => import("dsl/ecosystems/CareproviderSettingsReverseSearch"),
);
const NewOnboardingCareprovider = lazy(
  () => import("dsl/ecosystems/CareproviderOnboarding"),
);
const NewOnboardingCareproviderPage = lazy(
  () => import("dsl/ecosystems/CareproviderOnboarding/pages"),
);
const NewOnboardingPersonal = lazy(
  () => import("dsl/ecosystems/CareproviderOnboarding/PersonalOnboarding"),
);
const SettingsV2 = lazy(() => import("dsl/ecosystems/CareproviderSettings"));
const CareproviderProfile = lazy(
  () => import("dsl/ecosystems/CareproviderProfile"),
);
const CareproviderAccount = lazy(
  () => import("dsl/ecosystems/CareproviderAccount"),
);
const CareproviderNotificationPanel = lazy(
  () => import("dsl/ecosystems/CareproviderNotificationPanel"),
);

type CareproviderDashboardContext = {
  careprovider: Careprovider;
  token: string | undefined;
  updatedRequest: number | undefined;
};

export function useCareproviderDashboardContext() {
  return useOutletContext<CareproviderDashboardContext>();
}

const authPath = "/careprovider/auth";

export function AuthRoutes({ context }: { context: AnyObject }) {
  const apollo = useApolloClient();
  const location = useLocation();
  const redirectTo = getQueryVariable(location.search, REDIRECT_PARAM);
  const careproviderIdFromUrl = getQueryIntVariable(
    location.search,
    "careproviderid",
  );
  const careproviderIdFromStore = useLoggedCareproviderId();
  const careproviderId = parseInt(
    (careproviderIdFromUrl || careproviderIdFromStore || -1).toString(),
  );
  const isNotAuthenticated = context.checkNonAuth(careproviderId, apollo);

  const RedirectTo = useMemo(function redirecter() {
    return function redirect({
      careproviderId,
      noParams = false,
      useDefault = false,
    }: {
      careproviderId: number;
      noParams?: boolean;
      useDefault?: boolean;
    }) {
      let customTo = redirectTo;
      if (customTo && noParams) {
        customTo = customTo.split("?")[0];
      }
      const defaultRedirect = getDefaultRedirectPath(careproviderId);
      const to = useDefault ? defaultRedirect : customTo || defaultRedirect;
      return <Navigate to={to} replace />;
    };
  }, []);

  return isNotAuthenticated ? (
    <>
      <CareproviderAuthPage
        RedirectTo={RedirectTo}
        careproviderId={careproviderIdFromUrl}
        app={context.app}
      />
      <MagicLogin
        username={getQueryVariable(location.search, "username")}
        password={getQueryVariable(location.search, "password")}
      />
    </>
  ) : (
    <RedirectTo careproviderId={careproviderId} />
  );
}

function ProtectedRoutes({ context }: { context: AnyObject }) {
  const apolloClient = useApolloClient();
  const { careproviderId } = useIntParams<{ careproviderId: number }>({
    careproviderId: -1,
  });
  const token = getQueryVariable(location.search, "token");
  const toAuth = {
    pathname: authPath,
    search: `${REDIRECT_PARAM}=${encodeURIComponent(
      `${location.pathname}${location.search}`,
    )}&careproviderid=${careproviderId}${token ? `&token=${token}` : ""}`,
  };

  const isAuthenticated = context.checkAuth(careproviderId, apolloClient);
  const careproviderInStore = useSelector(selectLoggedCareproviderId);

  if (!isAuthenticated || !careproviderInStore) {
    return <Navigate to={toAuth} />;
  }
  // handle account switching careprovider
  if (careproviderInStore !== careproviderId) {
    return (
      <Navigate to={getDefaultRedirectPath(careproviderInStore)} replace />
    );
  }
  return (
    <CareproviderAccountsProvider>
      <PatientFilesProvider>
        <DashboardPage />
      </PatientFilesProvider>
    </CareproviderAccountsProvider>
  );
}

function DashboardPage() {
  const { careproviderId } = useIntParams<{ careproviderId: number }>({
    careproviderId: -1,
  });
  const updatedRequest = getUpdatedRequest();
  clearUpdatedRequest();
  const token = getQueryVariable(location.search, "token");
  const [queryProgress, careprovider] = useGetCareprovider({
    id: careproviderId,
    token,
  });

  return (
    <Result
      data={careprovider}
      id="careprovider-dashboard-routes"
      LoadingComponent={
        <SpinnerPage id="careprovider-dashboard-routes" height="100%" />
      }
      queryProgress={queryProgress}
    >
      {(careprovider) => (
        <ValidateCareprovider careprovider={careprovider} token={token}>
          <Outlet
            context={{
              updatedRequest,
              token,
              careprovider: careprovider,
            }}
          />
        </ValidateCareprovider>
      )}
    </Result>
  );
}

function RequestRoutes({
  careprovider,
  updatedRequest,
}: {
  careprovider: Careprovider;
  updatedRequest: number | undefined;
}) {
  const { param } = useParams<{ param: string }>();
  const tabOrAuctionRequestId = parseInt(param as string);
  const isAuctionRequestId = !isNaN(tabOrAuctionRequestId);
  useScrollRestoreCheckLocation(["requests", "careprovider"]);

  return (
    <Routes>
      {isAuctionRequestId ? (
        <Route
          element={
            <AuctionRequestHome
              auctionRequestId={tabOrAuctionRequestId}
              careproviderId={careprovider.id}
            />
          }
        >
          <Route
            index
            element={
              <AuctionRequest auctionRequestId={tabOrAuctionRequestId} />
            }
          />
          <Route path="barthel-index" element={<ConnectedBarthelIndex />} />
          <Route
            path="early-rehab-index"
            element={<ConnectedEarlyRehabIndex />}
          />
          <Route
            path="singer-assessment"
            element={
              <RequireCondition
                condition={activateSingerAssessment}
                redirectTo="new"
              />
            }
          >
            <Route index element={<SingerAssessmentSummaryPage />} />
          </Route>
          <Route
            path=":page"
            element={
              <AuctionRequest auctionRequestId={tabOrAuctionRequestId} />
            }
          />
          <Route
            path="*"
            element={<Navigate to={`${CAREPROVIDER_TAB_DEFAULT}`} replace />}
          />
        </Route>
      ) : (
        <>
          <Route
            index
            element={
              <ReceiverDashboard
                careprovider={careprovider}
                updatedRequest={updatedRequest}
                routeTab={param as CareproviderTab}
              />
            }
          />
        </>
      )}
    </Routes>
  );
}

function DashboardRoutesSwitch() {
  const { careprovider, token, updatedRequest } =
    useCareproviderDashboardContext();
  const { connected, hasAccounts } = useCareproviderAccountContext();
  const allowPWA = useAllowPWA();

  return (
    <Routes>
      <Route element={<CareproviderDashboard careprovider={careprovider} />}>
        <Route
          path="testseald"
          element={
            <RequireCondition condition={!isProd}>
              <TestSeald careprovider={careprovider} />
            </RequireCondition>
          }
        />
        <Route
          path="settings/*"
          element={<SettingsV2 careprovider={careprovider} />}
        >
          <Route
            path="account"
            element={<SettingsAccountPage careproviderId={careprovider.id} />}
          />
          <Route
            path="profile"
            element={<SettingsCompanyProfilePage careprovider={careprovider} />}
          />
          <Route
            path="services"
            element={<SettingsServicesPage careprovider={careprovider} />}
          />
          <Route
            path="team"
            element={<SettingsTeamPage careprovider={careprovider} />}
          />
          <Route
            path="notifications"
            element={<SettingsNotificationsPage careprovider={careprovider} />}
          />
          <Route
            path="pwa"
            element={
              <RequireCondition condition={allowPWA}>
                <SettingsPWAPage />
              </RequireCondition>
            }
          />
          <Route
            path="partner_clinics"
            element={<SettingsPartnerClinicsPage careprovider={careprovider} />}
          />
          <Route
            path="legal_documents"
            element={<SettingsLegalDocumentsPage careprovider={careprovider} />}
          />
          <Route
            path="extensions/*"
            element={<SettingsExtensionsPage careprovider={careprovider} />}
          />
          <Route
            path="capacity"
            element={<SettingsCapacityPage careprovider={careprovider} />}
          />
          <Route
            path="search"
            element={
              <RequireCondition
                condition={hasAccessToReverseSearch(careprovider)}
              >
                <SettingsReverseSearchPage careprovider={careprovider} />
              </RequireCondition>
            }
          />
          <Route path="*" element={<Navigate to="account" replace />} />
        </Route>

        {/* handle deprecated emails providing this link */}
        <Route
          path="recommendations"
          element={
            <Navigate to={getDefaultRedirectPath(careprovider.id)} replace />
          }
        />
        <Route
          path="profile"
          element={
            <RequireCondition condition={!connected && !hasAccounts}>
              <CareproviderProfile careprovider={careprovider} token={token} />
            </RequireCondition>
          }
        />
        <Route
          path="my_account"
          element={
            <RequireCondition condition={!!connected}>
              <CareproviderAccount />
            </RequireCondition>
          }
        />
        <Route path="my_password_change" element={<PasswordChangePage />} />
        <Route path="requests/*">
          <Route
            path={":param/*"}
            element={
              <RequestRoutes
                careprovider={careprovider}
                updatedRequest={updatedRequest}
              />
            }
          />
          <Route
            path="*"
            element={<Navigate to={`${CAREPROVIDER_TAB_DEFAULT}`} />}
          />
        </Route>
        <Route
          path="notification_panel"
          element={<CareproviderNotificationPanel />}
        />
      </Route>
    </Routes>
  );
}
export default function CareproviderRoutes({ ...context }: ReceiverContext) {
  return (
    <Routes>
      <Route path="/*" element={<RootPage appName="careprovider" />}>
        <Route path="diagnostic" element={<DiagnosticPage />} />
        <Route path="throwerror" element={<ThrowError />} />
        <Route path="logout" element={<LogoutPage />} />
        <Route path="careprovider/*">
          <Route path="info" element={<DebugPage />} />
          <Route path="auth" element={<AuthRoutes context={context} />} />
          <Route path="newonboarding/*">
            <Route
              path=":careproviderId/*"
              element={<NewOnboardingCareprovider />}
            >
              <Route index element={<NewOnboardingCareproviderPage />} />
              <Route path=":step" element={<NewOnboardingCareproviderPage />} />
            </Route>
            <Route path="personal/:careproviderId/*">
              <Route index element={<NewOnboardingPersonal />} />
              <Route path=":step" element={<NewOnboardingPersonal />} />
            </Route>
          </Route>
          <Route
            path="dashboard/:careproviderId/*"
            element={<ProtectedRoutes context={context} />}
          >
            <Route path="*" element={<DashboardRoutesSwitch />} />
          </Route>

          <Route
            path="reset/:token"
            element={<PasswordResetPage authRoute={authPath} />}
          />
          <Route path="*" element={<RedirectRoot to={authPath} />} />
        </Route>
        <Route path="*" element={<RedirectRoot to={authPath} />} />
      </Route>
    </Routes>
  );
}
