import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { CSSProperties } from "@mui/styles";
import { handleKeyDown } from "@recare/core/model/accessibility/keyboardActions";
import { RadioOption } from "@recare/core/types";
import {
  FormElement,
  FormElementProps,
  FormWatcher,
} from "@recare/react-forms-state";
import Tooltip from "ds/components/Tooltip";
import { ERROR_COLOR, SUCCESS_COLOR } from "ds/materials/colors";
import { HorizontalLayout } from "ds/materials/layouts";
import { border, margin } from "ds/materials/metrics";
import { FONT_SIZE_14, Subheading } from "ds/materials/typography";
import React, { HTMLAttributes } from "react";
import styled from "styled-components";

interface RadioDisplayProps extends HTMLAttributes<HTMLInputElement> {
  "data-testid"?: string;
}

export type RadioGroupProps = {
  alwaysChecked?: boolean;
  bold?: boolean;
  elementName: string;
  flex?: string;
  include?: number[] | null;
  label?: string;
  labelMargin?: string;
  marginBox?: string;
  marginWrapper?: string;
  options: Array<RadioOption>;
  showOnlyActive?: boolean;
};

type RadioValue = boolean | number | string | null;

type RadioWrapperProps = {
  alwaysChecked?: boolean;
  disabled?: boolean;
  elementName?: string;
  id: string;
  label: React.ReactNode | string;
  labelMargin?: string;
  labelStyle?: CSSProperties;
  marginBox?: string;
  maxWidth?: string;
  onChange: (arg: RadioValue) => void;
  radioValue: RadioValue;
  success?: boolean;
  tooltip?: string;
  value: RadioValue | undefined;
};

const RadioBox = styled.div`
  display: flex;
  align-items: stretch;
  flex: 0 0 auto;
  flex-direction: column;
`;

const RadioGroupBox = styled.div<{ flex?: string; margin?: string }>`
  display: flex;
  flex: ${({ flex }) => flex};
  align-items: stretch;
  flex-direction: column;
  margin: ${(props) => props.margin || undefined};
`;

const OptionsBox = styled.div<{ error?: boolean }>`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: stretch;
  border-bottom: ${(props) =>
    border({ width: props.error ? 2 : 0, color: ERROR_COLOR })};
`;

const SubForm = styled.div<{ isActive?: boolean }>`
  opacity: ${(props) => (props.isActive ? "1" : "0.7")};
  pointer-events: ${(props) => (props.isActive ? "auto" : "none")};
`;

function getFormControlLabelStyle({
  labelStyle = {},
  maxWidth,
  labelMargin,
  success,
}: Pick<
  RadioWrapperProps,
  "labelStyle" | "maxWidth" | "success" | "labelMargin"
>): CSSProperties {
  return {
    maxWidth,
    margin: labelMargin,
    "& .MuiFormControlLabel-label": {
      ...labelStyle,
      fontSize: FONT_SIZE_14,
      color: success ? SUCCESS_COLOR : undefined,
    },
  };
}

const radioStyle = {
  margin: margin(0, 1.5),
};

export function RadioWrapper({
  alwaysChecked,
  disabled,
  elementName,
  id,
  label,
  labelMargin,
  labelStyle,
  tooltip,
  marginBox,
  maxWidth = "100%",
  onChange = () => {},
  radioValue,
  success,
  value,
}: RadioWrapperProps) {
  function handleClick() {
    if (!alwaysChecked && value === radioValue) {
      onChange(null);
    } else {
      onChange(radioValue);
    }
  }

  return (
    <HorizontalLayout
      className={`radiobutton_${elementName}`}
      data-testid={id}
      id={id}
      margin={marginBox}
    >
      <Tooltip title={tooltip}>
        <FormControlLabel
          sx={getFormControlLabelStyle({
            labelStyle,
            maxWidth,
            labelMargin,
            success,
          })}
          onKeyDown={handleKeyDown({
            onConfirm: handleClick,
          })}
          control={
            <Radio
              checked={value === radioValue}
              color="primary"
              disabled={disabled}
              id={`radio_${id}`}
              inputProps={{ "data-testid": `input_${id}` } as RadioDisplayProps}
              onChange={handleClick}
              onClick={handleClick}
              style={radioStyle}
              value={elementName}
            />
          }
          label={label}
        />
      </Tooltip>
    </HorizontalLayout>
  );
}

export const RadioInputField = FormElement()<
  FormElementProps & RadioWrapperProps
>(RadioWrapper);

export default function RadioGroup({
  alwaysChecked,
  bold = false,
  elementName,
  flex = "1 1 auto",
  include,
  label,
  labelMargin,
  marginBox,
  marginWrapper,
  options,
  showOnlyActive,
}: RadioGroupProps) {
  let optionsToShow = options;

  if (include) {
    optionsToShow = options.filter((option) => include.includes(option?.value));
  }

  return (
    <RadioGroupBox margin={marginWrapper} flex={flex}>
      {label && (
        <Subheading margin={margin(1, 0)} bold={bold}>
          {label}
        </Subheading>
      )}
      <HorizontalLayout>
        <OptionsBox>
          {optionsToShow.map((option) => (
            <RadioBox key={`radiobutton-${option.value}`}>
              <RadioInputField
                elementName={elementName}
                id={`radiobutton_${elementName}_${option.id}`}
                label={option.label}
                radioValue={option.value}
                marginBox={marginBox}
                labelMargin={labelMargin}
                disabled={option.disabled}
                alwaysChecked={alwaysChecked}
              />
              {option.subForm && (
                <FormWatcher watchPath={elementName}>
                  {({ value }) => {
                    const isActive =
                      value && value[elementName] === option.value;

                    return (
                      <div
                        style={{
                          display:
                            showOnlyActive && !isActive ? "none" : undefined,
                        }}
                      >
                        <SubForm isActive={isActive}>{option.subForm}</SubForm>
                      </div>
                    );
                  }}
                </FormWatcher>
              )}
            </RadioBox>
          ))}
        </OptionsBox>
      </HorizontalLayout>
    </RadioGroupBox>
  );
}
