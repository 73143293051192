import { isProd } from "@recare/core/model/utils/featureFlags";
import { Account } from "@recare/core/types";
import { ReactNode, createContext, useContext } from "react";

export type CareproviderAccountContext = {
  accounts?: Account[] | null | undefined;
  connected?: boolean;
  hasAccounts?: boolean;
  loggedAccount?: Account | undefined;
};

const defaultCareproviderAccountContext = {
  connected: false,
  hasAccounts: false,
  accounts: undefined,
  loggedAccount: undefined,
};

const CareproviderAccountContext = createContext<CareproviderAccountContext>(
  defaultCareproviderAccountContext,
);

export function useCareproviderAccountContext() {
  const context = useContext(CareproviderAccountContext);
  if (!isProd && (!context || context === defaultCareproviderAccountContext)) {
    console.error(
      "You are attempting to use useCareproviderAccountContext outside of a CareproviderAccountProvider",
    );
  }
  return context;
}

export function CareproviderAccountProvider({
  children,
  value,
}: {
  children: ReactNode;
  value: CareproviderAccountContext;
}) {
  return (
    <CareproviderAccountContext.Provider value={value}>
      {children}
    </CareproviderAccountContext.Provider>
  );
}
