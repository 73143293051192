import { TRACK_EVENTS } from "@recare/core/consts";
import {
  convertModelDefinition,
  FormRenderProps,
  validateModel,
  valueDef,
} from "@recare/react-forms-state";
import { useTranslations } from "@recare/translations";
import { useCreateFeedbackPatientInfoMissing } from "apollo/hooks/mutations";
import RSButton from "ds/components/RSButton";
import TextAreaDialog from "ds/components/TextAreaDialog";
import { useModal } from "dsl/hooks";
import { useTracking } from "react-tracking";

export default function PatientInfoMissing({
  auctionRequestId,
  careproviderId,
}: {
  auctionRequestId: number | null | undefined;
  careproviderId: number | null | undefined;
}) {
  const translations = useTranslations();
  const [modalOpen, setModalOpen] = useModal();
  const { trackEvent } = useTracking();

  const [feedbackPatientInfoMissing, queryProgress, resetProgress] =
    useCreateFeedbackPatientInfoMissing({
      careproviderId,
      auctionRequestId,
      onCompleted: () => {
        trackEvent({
          name: TRACK_EVENTS.PATIENT_INFORMATION_MISSING_SUBMITTED,
        });
        setTimeout(() => {
          setModalOpen(false);
          resetProgress();
        }, 500);
      },
      refetchPatientEvents: true,
    });

  const modelDefinition = convertModelDefinition({
    ...valueDef("feedback"),
  });

  return (
    <>
      <RSButton
        color="primary"
        id="missing_information"
        loading="na"
        onClick={() => {
          trackEvent({
            name: TRACK_EVENTS.PATIENT_INFORMATION_MISSING_TAP_ON_BUTTON,
          });
          setModalOpen(true);
        }}
        variant="text"
      >
        {translations.careproviderApp.commentOnProfile.missingInformation}
      </RSButton>
      <FormRenderProps
        formInputValue={{ feedback: "" }}
        convertIn={(i) => i}
        convertOut={(o) => o}
        validate={validateModel(modelDefinition)}
        onSubmit={(value) => feedbackPatientInfoMissing(value)}
      >
        {({ submit }) => (
          <TextAreaDialog
            disableOnEmpty
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            onSubmit={submit}
            submitText={translations.actions.submit}
            queryProgress={queryProgress}
            title={translations.careproviderApp.commentOnProfile.modalTitle}
            placeholder={
              translations.careproviderApp.commentOnProfile.modalPlaceholder
            }
            subtitle={
              translations.careproviderApp.commentOnProfile.subtitleInfo
            }
            elementName="feedback"
          />
        )}
      </FormRenderProps>
    </>
  );
}
