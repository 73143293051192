import {
  FILE_TABLE_ACTION_DOWNLOAD,
  QUERY_PROGRESS_FAILED,
  QUERY_PROGRESS_PENDING,
  QUERY_PROGRESS_SUCCEED,
} from "@recare/core/consts";
import { onPremHttpRequest } from "@recare/core/model/careseekers";
import { base64ToBytes } from "@recare/core/model/crypto";
import { createAndClickLink, downloadFile } from "@recare/core/model/files";
import { decryptFromSession } from "@recare/core/seald/sessions";
import { FileHISStateful, Filev2, QueryProgress } from "@recare/core/types";
import { useTranslations } from "@recare/translations";
import Translations from "@recare/translations/types";
import {
  GeneralListAction,
  SetAction,
} from "ds/components/Tables/GeneralTable/MenuActions";
import { useHL7ImportStatusContext } from "dsl/atoms/HL7ImportStatusContext";
import { isFileHISStateful } from "dsl/organisms/FileTables";
import { useNotification } from "dsl/organisms/NotificationProvider";
import { Dispatch, SetStateAction, useEffect } from "react";
import request from "superagent";
import { FileFeatureData } from "./useFilesFeature";

export async function handleFileHISDownload({
  externalId,
  file,
  notify,
  on_premise_authorization_token,
  on_premise_domain_ssl,
  setAction,
  setProgress,
  translations,
}: {
  externalId: string;
  file: FileHISStateful;
  notify: ReturnType<typeof useNotification>;
  on_premise_authorization_token: string | undefined;
  on_premise_domain_ssl: string | undefined;
  setAction: SetAction<FileFeatureData>;
  setProgress: Dispatch<SetStateAction<QueryProgress>>;
  translations: Translations;
}) {
  try {
    const response = await onPremHttpRequest({
      on_premise_domain_ssl,
      path: `patients/${externalId}/documents/${file.document_id}`,
      method: "GET",
      on_premise_authorization_token,
    });

    if (!response.ok) {
      throw new Error(
        `Failed to fetch file: ${response.status} ${response.statusText}`,
      );
    }

    const blob = await response.blob();
    createAndClickLink({ blob, fileName: file.document_file_name });

    setProgress(QUERY_PROGRESS_SUCCEED);
  } catch (err) {
    console.error(err);
    setProgress(QUERY_PROGRESS_FAILED);
    notify({
      message: translations.fileSection.toastError,
      type: "error",
    });
  } finally {
    setAction(undefined);
  }
}

export function useDownloadFile({
  action,
  externalId,
  setAction,
  setProgress,
}: {
  action: GeneralListAction<FileHISStateful | Filev2> | undefined;
  externalId: string;
  setAction: SetAction<FileFeatureData>;
  setProgress: Dispatch<SetStateAction<QueryProgress>>;
}) {
  const { on_premise_authorization_token, on_premise_domain_ssl } =
    useHL7ImportStatusContext();
  const translations = useTranslations();
  const notify = useNotification();

  async function handleFileDownload({ file }: { file: Filev2 }) {
    setProgress(QUERY_PROGRESS_PENDING);

    try {
      if (!file.link || !file.file_name?.seald_content || !file.file_type) {
        throw new Error(
          "[handleFileDownload] link, encrypted file_name and file type must be defined!",
        );
      }

      const { text } = await request("GET", file.link).set("Accept", "*/*");

      if (!text) {
        throw new Error("[handleFileDownload] Couldn't fetch encrypted file!");
      }

      const decryptedBase64File = await decryptFromSession({
        context: "file",
        encryptedMessage: text,
      });

      if (!decryptedBase64File) {
        throw new Error("[handleFileDownload] Couldn't decrypt file content!");
      }

      const fileName = await decryptFromSession({
        encryptedMessage: file.file_name.seald_content,
        context: `[handleFileDownload] - file_name`,
      });

      if (!fileName) {
        throw new Error("[handleFileDownload] Couldn't decrypt file name!");
      }

      downloadFile({
        content: base64ToBytes(decryptedBase64File),
        fileName,
        type: file.file_type,
      });

      setProgress(QUERY_PROGRESS_SUCCEED);
    } catch (err) {
      console.error(err);
      setProgress(QUERY_PROGRESS_FAILED);
      notify({
        message: translations.fileSection.toastError,
        type: "error",
      });
    } finally {
      setAction(undefined);
    }
  }

  useEffect(() => {
    if (action?.type === FILE_TABLE_ACTION_DOWNLOAD) {
      if (isFileHISStateful(action.data)) {
        handleFileHISDownload({
          file: action.data,
          externalId,
          on_premise_authorization_token,
          on_premise_domain_ssl,
          notify,
          setAction,
          setProgress,
          translations,
        });
        return;
      }

      handleFileDownload({ file: action.data });
    }
  }, [action?.type]);
}
