import { Query } from "@apollo/client/react/components";
import {
  GET_CAREPROVIDER,
  GET_CAREPROVIDER_TOKEN,
} from "@recare/core/apollo/graphql";
import { Account as AccountType, Careprovider } from "@recare/core/types";
import { CareproviderAccountProvider } from "context/CareproviderAccountContext";
import React, { ReactNode } from "react";
import { compose } from "recompose";
import {
  ConnectedCareprovider,
  ConnecterWithLoggedAccount,
  ConnecterWithLoggedCareprovider,
  ConnecterWithQueryToken,
} from "reduxentities/selectors";

type CareproviderAccountsProviderProps = {
  account: AccountType;
  careproviderId: number | string;
  children: React.ReactNode;
  connected: boolean;
  token?: string;
};

const GetCachedProviderQuery = ({
  careproviderId,
  children,
  token,
}: {
  careproviderId: number;
  children: (careprovider: Careprovider | undefined) => React.ReactNode;
  token?: string;
}) => (
  <Query
    query={
      token
        ? GET_CAREPROVIDER_TOKEN({ withEvents: false, token })
        : GET_CAREPROVIDER({ withEvents: false })
    }
    variables={{ id: careproviderId || -1 }}
    fetchPolicy="cache-only"
  >
    {({ data }: { data: { careprovider: Careprovider } | null }) => {
      const careprovider = data?.careprovider;
      return <>{children(careprovider)}</>;
    }}
  </Query>
);

const enhance = compose<
  CareproviderAccountsProviderProps,
  { children: ReactNode }
>(
  ConnectedCareprovider,
  ConnecterWithLoggedCareprovider,
  ConnecterWithQueryToken,
  ConnecterWithLoggedAccount,
);

function CareproviderAccountsProvider({
  account,
  careproviderId,
  children,
  connected,
  token,
}: CareproviderAccountsProviderProps) {
  const parsedCareproviderId =
    typeof careproviderId === "string"
      ? parseInt(careproviderId)
      : careproviderId;

  return (
    <GetCachedProviderQuery careproviderId={parsedCareproviderId} token={token}>
      {(careprovider) => (
        <CareproviderAccountProvider
          value={{
            connected,
            hasAccounts: !!careprovider?.accounts?.length,
            accounts: careprovider?.accounts,
            loggedAccount: account,
          }}
        >
          {children}
        </CareproviderAccountProvider>
      )}
    </GetCachedProviderQuery>
  );
}

export default enhance(CareproviderAccountsProvider);
