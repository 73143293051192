import { CAREPROVIDER_TAB_TYPE_NEW, REDIRECT_PARAM } from "@recare/core/consts";
import { MessengerTabKinds } from "@recare/core/types";
import { setUpdatedRequest } from "dsl/ecosystems/AuctionRequest/updatedRequest";
import { Location, NavigateFunction } from "react-router-dom";
import { generateParams } from "./helpers";

export const getCareproviderNavigationHandlers = ({
  careproviderId,
  location,
  navigate,
}: {
  careproviderId?: string;
  location: Location;
  navigate: NavigateFunction;
}) => {
  return {
    // Authentication
    replaceByAuth() {
      navigate({ pathname: "/careprovider/auth" }, { replace: true });
    },
    changePassword(id: number) {
      navigate({
        pathname: `/careprovider/dashboard/${id}/my_password_change?${REDIRECT_PARAM}=${encodeURIComponent(
          `${location.pathname}${location.search}`,
        )}`,
      });
    },
    goToChangePassword() {
      navigate({
        pathname: `/careprovider/dashboard/${careproviderId}/my_password_change?${REDIRECT_PARAM}=${encodeURIComponent(
          `${location.pathname}${location.search}`,
        )}`,
      });
    },

    // User account
    goToProfile(id: number) {
      navigate({
        pathname: `/careprovider/dashboard/${id}/my_account`,
        search: location.search,
      });
    },

    // Onboarding
    goToPersonalOnboarding({
      careproviderId,
      step = "account",
    }: {
      careproviderId: number;
      step?: string;
    }) {
      navigate({
        pathname: `/careprovider/newonboarding/personal/${careproviderId}/${step}`,
        search: location.search,
      });
    },

    // Dashboard
    goToRequestDashboard(
      parameters: {
        pgs?: string;
        routeTab?: string;
        updatedRequestId?: number;
      } = {},
    ) {
      const { pgs, routeTab, updatedRequestId } = {
        routeTab: CAREPROVIDER_TAB_TYPE_NEW,
        ...parameters,
      };

      if (updatedRequestId) setUpdatedRequest(updatedRequestId);
      const search = generateParams([["pgs", pgs]]) || "";

      navigate({
        pathname: `/careprovider/dashboard/${careproviderId}/requests/${routeTab}`,
        search,
      });
    },

    goToFilteredDashboard(filterId?: number) {
      const search = generateParams([["filterid", filterId]]);

      navigate({
        pathname: `/careprovider/dashboard/${careproviderId}/requests/new`,
        search,
      });
    },

    // Requests
    goToRequest(requestId: number, careproviderId: number) {
      if (requestId <= 0) {
        console.error(
          `[DEV-14068] [goToRequest] tried to access bad request id ${requestId}`,
        );
        return;
      }
      navigate({
        pathname: `/careprovider/dashboard/${careproviderId}/requests/${requestId}`,
      });
    },
    goToAuctionRequest({
      params,
      requestId,
    }: {
      params?: {
        pgs?: string;
        position?: number | null;
        recommendation?: boolean;
      };
      requestId: number;
    }) {
      const search = generateParams([
        ["pgs", params?.pgs],
        ["position", params?.position],
        ["recommendation", params?.recommendation ? "true" : undefined],
      ]);
      navigate({
        pathname: `/careprovider/dashboard/${careproviderId}/requests/${requestId}`,
        search,
      });
    },
    goToAuctionRequestTab({ tab }: { tab: MessengerTabKinds }) {
      const search = new URLSearchParams(location.search);
      search.set("activeTab", tab);
      navigate({ search: search.toString() });
    },
    goToRequestsLogged(careproviderId: number, updatedRequest?: number) {
      const search = updatedRequest ? `?updatedRequest=${updatedRequest}` : "";
      navigate({
        pathname: `/careprovider/dashboard/${careproviderId}/requests`,
        search,
      });
    },
    goToBarthelIndexDetails({
      auctionRequestId,
      careproviderId,
    }: {
      auctionRequestId: number;
      careproviderId: number;
    }) {
      navigate({
        pathname: `/careprovider/dashboard/${careproviderId}/requests/${auctionRequestId}/barthel-index`,
      });
    },
    goToEarlyRehabIndexDetails({
      auctionRequestId,
      careproviderId,
    }: {
      auctionRequestId: number;
      careproviderId: number;
    }) {
      navigate({
        pathname: `/careprovider/dashboard/${careproviderId}/requests/${auctionRequestId}/early-rehab-index`,
      });
    },
    goToSingerAssessment({
      auctionRequestId,
      careproviderId,
    }: {
      auctionRequestId: number;
      careproviderId: number;
    }) {
      navigate({
        pathname: `/careprovider/dashboard/${careproviderId}/requests/${auctionRequestId}/singer-assessment`,
      });
    },

    // Notifications
    goToCareproviderNotificationPanel() {
      navigate({
        pathname: `/careprovider/dashboard/${careproviderId}/notification_panel`,
      });
    },

    // Settings
    goToSettingsAccount(careproviderId: number) {
      navigate({
        pathname: `/careprovider/dashboard/${careproviderId}/settings/accounts`,
      });
    },
    goToCareproviderSettings() {
      navigate({
        pathname: `/careprovider/dashboard/${careproviderId}/settings`,
      });
    },
    goToCareproviderSettingsAccount() {
      navigate({
        pathname: `/careprovider/dashboard/${careproviderId}/settings/account`,
      });
    },
    goToCareproviderSettingsCompanyProfile() {
      navigate({
        pathname: `/careprovider/dashboard/${careproviderId}/settings/profile`,
      });
    },
    goToCareproviderSettingsServices() {
      navigate({
        pathname: `/careprovider/dashboard/${careproviderId}/settings/services`,
      });
    },
    goToCareproviderSettingsSearch() {
      navigate({
        pathname: `/careprovider/dashboard/${careproviderId}/settings/search`,
      });
    },
    goToCareproviderSettingsTeam() {
      navigate({
        pathname: `/careprovider/dashboard/${careproviderId}/settings/team`,
      });
    },
    goToCareproviderSettingsNotifications() {
      navigate({
        pathname: `/careprovider/dashboard/${careproviderId}/settings/notifications`,
      });
    },
    goToCareproviderSettingsPWA() {
      navigate({
        pathname: `/careprovider/dashboard/${careproviderId}/settings/pwa`,
      });
    },
    goToCareproviderSettingsPartnerClinics() {
      navigate({
        pathname: `/careprovider/dashboard/${careproviderId}/settings/partner_clinics`,
      });
    },
    goToCareproviderSettingsLegalDocuments() {
      navigate(
        `/careprovider/dashboard/${careproviderId}/settings/legal_documents`,
      );
    },
    goToCareproviderSettingsCapacity() {
      navigate({
        pathname: `/careprovider/dashboard/${careproviderId}/settings/capacity`,
      });
    },
    goToCareproviderSettingsExtensions() {
      return navigate({
        pathname: `/careprovider/dashboard/${careproviderId}/settings/extensions`,
      });
    },
  };
};
