import { formatUnixDate } from "@recare/core/model/utils/dates";
import { BarthelIndex, Patient } from "@recare/core/types";
import { useLocale, useTranslations } from "@recare/translations";
import { INFO_CARD_BORDER_BOTTOM } from "ds/materials/colors";
import { dp } from "ds/materials/metrics";
import { usePrintWindow } from "dsl/atoms/PrintButton";
import { useOnEnter } from "dsl/atoms/Routes";
import { useScrollToElement } from "dsl/hooks/useScrollTo";
import { Fragment } from "react";
import { BarthelElement, getBarthelElements } from "./elements";
import { BarthelTitle, ReturnBar, sumValues } from "./shared";
import {
  BarthelBody2Desktop,
  BarthelDividerRow,
  BarthelResult,
  BarthelSubheading2Desktop,
  BarthelTable,
  BarthelTableCell,
  BarthelTableRow,
  BarthelWrapper,
  DisplayWrapper,
  ReturnBarRef,
  TitleSubheading2Desktop,
  TobBarWrapper,
} from "./styles";

function getElementValue(list: BarthelIndex, barthelElement: BarthelElement) {
  return list?.form?.[barthelElement.elementName];
}

function Divider({ columnsNumber }: { columnsNumber: number }) {
  const cols = Array.from(Array(columnsNumber + 1).keys());
  return (
    <BarthelDividerRow>
      {cols.map((col) => (
        <BarthelTableCell key={col} />
      ))}
    </BarthelDividerRow>
  );
}

export function BarthelSummary({
  barthelIndexList,
  patient,
}: {
  barthelIndexList: BarthelIndex[];
  patient?: Patient;
}) {
  const translations = useTranslations();
  const locale = useLocale();
  const elements = getBarthelElements(translations);

  if (!barthelIndexList?.length) return null;

  return (
    <BarthelWrapper>
      <BarthelTable>
        <tbody>
          <tr>
            <BarthelTableCell noBorder height={dp(50)}>
              <BarthelTitle
                translations={translations}
                patient={patient}
                print
              />
            </BarthelTableCell>
            {barthelIndexList.map((i) => (
              <BarthelTableCell
                key={i.date}
                textAlign="center"
                noBorder
                height={dp(50)}
              >
                <BarthelBody2Desktop fontWeight="bold">
                  {formatUnixDate(i.date, locale)}
                </BarthelBody2Desktop>
              </BarthelTableCell>
            ))}
          </tr>
          <tr>
            <BarthelTableCell></BarthelTableCell>
            {barthelIndexList.map((i) => (
              <BarthelTableCell key={i.date} textAlign="center">
                <BarthelResult
                  fontWeight="bold"
                  fontSize={dp(20)}
                  data-testid="resultWrapper"
                >
                  = {sumValues(i.form)}
                </BarthelResult>
              </BarthelTableCell>
            ))}
          </tr>
          {elements.map((barthelElement: BarthelElement, index: number) => (
            <Fragment key={barthelElement.elementName}>
              {index !== 0 && (
                <>
                  <BarthelTableRow>
                    <BarthelTableCell>
                      <BarthelSubheading2Desktop> </BarthelSubheading2Desktop>
                    </BarthelTableCell>
                    {barthelIndexList.map((list) => (
                      <BarthelTableCell key={list.date} />
                    ))}
                  </BarthelTableRow>
                  <BarthelTableRow>
                    <BarthelTableCell>
                      <BarthelSubheading2Desktop> </BarthelSubheading2Desktop>
                    </BarthelTableCell>
                    {barthelIndexList.map((list) => (
                      <BarthelTableCell key={list.date} />
                    ))}
                  </BarthelTableRow>
                  <BarthelTableRow>
                    <BarthelTableCell>
                      <BarthelSubheading2Desktop> </BarthelSubheading2Desktop>
                    </BarthelTableCell>
                    {barthelIndexList.map((list) => (
                      <BarthelTableCell key={list.date} />
                    ))}
                  </BarthelTableRow>
                </>
              )}
              <BarthelTableRow>
                <BarthelTableCell>
                  <TitleSubheading2Desktop bold>
                    {barthelElement.title}
                  </TitleSubheading2Desktop>
                </BarthelTableCell>
                {barthelIndexList.map((list) => (
                  <BarthelTableCell key={list.date} />
                ))}
              </BarthelTableRow>
              {barthelElement.values.map(
                (
                  barthelValue: {
                    label: string;
                    value: number;
                  },
                  barthelElementValuesIndex: number,
                ) => (
                  <>
                    <BarthelTableRow key={JSON.stringify(barthelValue)}>
                      <BarthelTableCell borderRight={INFO_CARD_BORDER_BOTTOM}>
                        <BarthelBody2Desktop maxWidth="auto">{`${barthelValue.value} - ${barthelValue.label}`}</BarthelBody2Desktop>
                      </BarthelTableCell>
                      {barthelIndexList.map((list) => {
                        const valueIsInCorrectRow =
                          getElementValue(list, barthelElement) ===
                          barthelValue?.value;
                        return (
                          <BarthelTableCell
                            key={JSON.stringify(list)}
                            textAlign="center"
                          >
                            <BarthelBody2Desktop>
                              {valueIsInCorrectRow ? barthelValue?.value : ""}
                            </BarthelBody2Desktop>
                          </BarthelTableCell>
                        );
                      })}
                    </BarthelTableRow>
                    {barthelElementValuesIndex !==
                      barthelElement.values.length - 1 && (
                      <Divider columnsNumber={barthelIndexList.length} />
                    )}
                  </>
                ),
              )}
            </Fragment>
          ))}
        </tbody>
      </BarthelTable>
    </BarthelWrapper>
  );
}

export default function SummaryPage({
  auctionId,
  patient,
}: {
  auctionId: number;
  patient: Patient;
}) {
  useOnEnter();
  usePrintWindow();
  const translations = useTranslations();
  const barthelIndexList =
    patient.diagnosis?.dependency_diagnosis?.barthel_index_list || [];
  const { ref } = useScrollToElement("instant");

  const sortedBarthelIndexList = barthelIndexList
    .clone()
    .sort((a, b) => a.date - b.date);
  return (
    <DisplayWrapper>
      <ReturnBarRef ref={ref}>
        <ReturnBar patient={patient} auctionId={auctionId} />
      </ReturnBarRef>
      <TobBarWrapper>
        <BarthelTitle patient={patient} translations={translations} />
      </TobBarWrapper>
      <BarthelSummary
        barthelIndexList={sortedBarthelIndexList}
        patient={patient}
      />
    </DisplayWrapper>
  );
}
