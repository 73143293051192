import { RESPONSIVE_BREAKPOINT_TABLET } from "@recare/core/consts";
import { RecareNameLogo } from "ds/components/RecareLogo";
import {
  ACCENT_COLOR,
  PROVIDER_PRIMARY_DARK_COLOR,
  WHITE,
} from "ds/materials/colors";
import { MEDIUM, border, dp, margin, sizing } from "ds/materials/metrics";
import { Caption } from "ds/materials/typography";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import styled from "styled-components";

const STATUS_DONE = "done";
const STATUS_ACTIVE = "active";
const STATUS_PENDING = "pending";

function getStatusColor(status: string, constrast?: boolean) {
  switch (status) {
    case STATUS_ACTIVE:
    case STATUS_DONE:
      return ACCENT_COLOR;
    default:
      return constrast ? WHITE : PROVIDER_PRIMARY_DARK_COLOR;
  }
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-width: ${sizing(25)};
  background-color: ${PROVIDER_PRIMARY_DARK_COLOR};
  align-items: flex-start;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.16);
  position: relative;
  @media screen and (max-width: ${dp(RESPONSIVE_BREAKPOINT_TABLET)}) {
    & {
      display: flex;
      max-height: ${sizing(9)};
    }
  }
`;

const StepsWrapper = styled.div<{ isPersonalOnboarding: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: ${({ isPersonalOnboarding }) =>
    isPersonalOnboarding ? sizing(20) : sizing(40)};
  position: absolute;
  top: ${sizing(20)};
  left: ${dp(60)};
  margin: auto;
  justify-content: space-around;
  align-items: flex-start;
  @media screen and (max-width: ${dp(RESPONSIVE_BREAKPOINT_TABLET)}) {
    & {
      display: none;
    }
  }
`;

const StepCircle = styled.div<{ status: string }>`
  width: ${sizing(3)};
  height: ${sizing(3)};
  border-radius: ${dp(18)};
  border: ${border({ color: WHITE, width: 3 })};
  background-color: ${(props) => getStatusColor(props.status)};
  position: absolute;
  left: ${dp(125)};
  display: flex;
`;

const StepCaption = styled(Caption)<{ status: string }>`
  margin: 0;
  display: flex;
  position: relative;
  align-items: center;
  color: ${(props) => getStatusColor(props.status, true)};
  text-transform: uppercase;
  font-weight: bold;
  white-space: normal;
`;

function calculateStatus(currentIndex: number, activeIndex: number) {
  if (currentIndex < activeIndex) return STATUS_DONE;
  if (currentIndex === activeIndex) return STATUS_ACTIVE;
  return STATUS_PENDING;
}

export default function Stepper({
  activeStep,
  isPersonalOnboarding = false,
  showSteps,
  steps,
}: {
  activeStep: string | undefined;
  isPersonalOnboarding?: boolean;
  showSteps: boolean;
  steps: Array<{ key: string; value: string }>;
}) {
  const { isTablet } = useMedia();
  if (!activeStep) return null;
  const activeIndex = steps.map((step) => step.key).indexOf(activeStep);

  return (
    <ContentWrapper>
      <RecareNameLogo
        size={MEDIUM}
        style={{
          margin: isTablet ? margin(3) : margin(4, 3),
          alignSelf: "center",
        }}
      />
      {showSteps && (
        <StepsWrapper isPersonalOnboarding={isPersonalOnboarding}>
          {steps.map((step, index) => {
            const status = calculateStatus(index, activeIndex);
            return (
              <StepCaption status={status} key={step.key}>
                <span style={{ maxWidth: sizing(15) }}>{step.value}</span>
                <StepCircle status={status} />
              </StepCaption>
            );
          })}
        </StepsWrapper>
      )}
    </ContentWrapper>
  );
}
