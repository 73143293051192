import { useUpdateBrowserSubscription } from "@recare/app/src/apollo/hooks/mutations";
import { useLoggedInAccount } from "@recare/app/src/reduxentities/selectors";
import {
  APP_CAREPROVIDER,
  APP_CLINIC,
  NOTIFICATION_PERMISSION_DEFAULT,
} from "@recare/core/consts";
import { getErrorMessage } from "@recare/core/model/utils/errors";
import {
  activateBrowserNotification,
  activatePWA,
} from "@recare/core/model/utils/featureFlags";
import { useEnvContext } from "context/EnvContext";
import { Dispatch, SetStateAction, useState } from "react";
import { useBrowserContext } from "../BrowserProvider";

// ====================== UTILS ======================
const encodeB64KeyToUint8ArrayBuffer = (base64String: string) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    // eslint-disable-next-line no-useless-escape
    .replace(/\-/g, "+")
    .replace(/_/g, "/");
  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export const useAllowBrowserNotifications = (): boolean => {
  const { app } = useEnvContext();

  if (!activateBrowserNotification) {
    return false;
  }

  if (!([APP_CAREPROVIDER, APP_CLINIC] as number[]).includes(app)) {
    return false;
  }

  return true;
};

export const useAllowPWA = (): boolean => {
  const browserContext = useBrowserContext();
  const allowBrowserNotifications = useAllowBrowserNotifications();

  if (!activatePWA || !allowBrowserNotifications) {
    return false;
  }

  const isMobileDevice =
    browserContext.browser?.getPlatformType() === "mobile" &&
    ["ios", "android"].includes(browserContext.browser?.getOSName(true));

  return isMobileDevice;
};

export const useAllowDesktopNotifications = (): boolean => {
  const allowPWA = useAllowPWA();
  const allowBrowserNotifications = useAllowBrowserNotifications();

  return allowBrowserNotifications && !allowPWA;
};

export const NOTIFICATION_PERMISSION_STATUS_UNSUPPORTED = "unsupported";

export type NotificationPermissionState =
  | PermissionStatus["state"]
  | NotificationPermission
  | typeof NOTIFICATION_PERMISSION_STATUS_UNSUPPORTED;

export type NotificationPermissionType = {
  error: string | null;
  isLoading: boolean;
  state: NotificationPermissionState;
};

export type NotificationPermissionContextType = {
  askPermission: () => Promise<void>;
  notificationPermissionState: NotificationPermissionType;
  pushSubscription: PushSubscriptionJSON | null;
  resubscribeUser: () => Promise<void>;
};

export const defaultNotificationPermission: NotificationPermissionType = {
  state: NOTIFICATION_PERMISSION_DEFAULT as NotificationPermissionState,
  isLoading: false,
  error: null,
};

// ====================== LOGIC ======================

//Register Service Worker
export async function registerServiceWorker() {
  if (!("serviceWorker" in navigator)) {
    console.log("Service Worker isn't supported on this browser.");
    return;
  }
  try {
    const origin = window.location.origin;
    const serviceWorkerRegistration =
      await navigator.serviceWorker.getRegistration(origin);
    if (serviceWorkerRegistration) return;
    await navigator.serviceWorker.register("/serviceWorker.js");
  } catch (error) {
    console.error("Service worker registration failed:", error);
  }
}

// Get the Service Worker registration and then the subscription info
async function getSubscriptionInfo() {
  if (!("serviceWorker" in navigator)) {
    console.log("Service Worker isn't supported on this browser.");
    return null;
  }
  if (!("PushManager" in window)) {
    console.log("Push messaging isn't supported on this browser.");
    return null;
  }
  try {
    const origin = window.location.origin;
    const serviceWorkerRegistration =
      await navigator.serviceWorker.getRegistration(origin);
    if (serviceWorkerRegistration) {
      const subscriptionInfo =
        await serviceWorkerRegistration.pushManager.getSubscription();
      return subscriptionInfo;
    }
    return null;
  } catch (error) {
    console.error("Get SubscriptionInfo failed:", error);
    return null;
  }
}

function timeout(ms: number) {
  return new Promise((_, reject) => {
    setTimeout(() => {
      reject(new Error("Timed out in " + ms + "ms."));
    }, ms);
  });
}

export async function getSubscriptionInfoWithTimeout() {
  try {
    const subscriptionInfo = await Promise.race([
      getSubscriptionInfo(),
      timeout(2000),
    ]);
    return subscriptionInfo as PushSubscription | undefined;
  } catch (error) {
    console.error(
      "Get Service Worker Registration and SubscriptionInfo failed:",
      error,
    );
  }
}

// Public Key must be the same key as backend uses.
const VAPID_PUBLIC_KEY =
  "BMJ4MRuhgw9D192Rhk7m-ePSySDzr5P1pnYcrhljm5G1brTqmhUrIushv6mDjBhlrWD-MK497wiHBv8cS8ruMyM";

// Subscribe to push server
async function subscribeUserToPush() {
  try {
    const applicationServerKey =
      encodeB64KeyToUint8ArrayBuffer(VAPID_PUBLIC_KEY);

    const options = { applicationServerKey, userVisibleOnly: true };

    const origin = window.location.origin;
    const serviceWorkerRegistration =
      await navigator.serviceWorker.getRegistration(origin);

    if (serviceWorkerRegistration) {
      return await serviceWorkerRegistration.pushManager.subscribe(options);
    }
  } catch (error) {
    console.error("Error subscribing to push server:", error);
  }
}

export const isValidSession = (): string | undefined => {
  if (!("Notification" in window) || typeof Notification === "undefined") {
    return "Browser Notification isn't supported on this browser.";
  }

  if (!ServiceWorkerRegistration) {
    return "No ServiceWorkerRegistration.";
  }

  if (!("showNotification" in ServiceWorkerRegistration.prototype)) {
    return "Browser Notification isn't supported in the service worker.";
  }

  if (!("PushManager" in window)) {
    return "Push messaging isn't supported on this browser.";
  }
};

const handleSubscribeToPush = async (
  updateBrowserSubscription: ReturnType<typeof useUpdateBrowserSubscription>[0],
  setPushSubscription: Dispatch<SetStateAction<PushSubscriptionJSON | null>>,
) => {
  await registerServiceWorker();
  const newSubscription = await subscribeUserToPush();
  const newSubscriptionJSON = newSubscription?.toJSON();
  if (newSubscriptionJSON?.endpoint && newSubscriptionJSON?.keys) {
    setPushSubscription(newSubscriptionJSON);
    const id_token = localStorage.getItem("id_token");
    await updateBrowserSubscription({ ...newSubscriptionJSON, id_token });
  } else {
    throw new Error(
      `Invalid subscription - ${JSON.stringify(newSubscriptionJSON, null, 2)}`,
    );
  }
};

async function askPermission({
  setNotificationPermissionState,
  setPushSubscription,
  updateBrowserSubscription,
}: {
  setNotificationPermissionState: Dispatch<
    SetStateAction<NotificationPermissionType>
  >;
  setPushSubscription: Dispatch<SetStateAction<PushSubscriptionJSON | null>>;
  updateBrowserSubscription: ReturnType<typeof useUpdateBrowserSubscription>[0];
}): Promise<string | undefined> {
  const error = isValidSession();
  if (error) {
    setNotificationPermissionState({
      state: NOTIFICATION_PERMISSION_STATUS_UNSUPPORTED,
      isLoading: false,
      error,
    });
    return;
  }

  if (Notification.permission !== "default") {
    setNotificationPermissionState({
      state: Notification.permission,
      isLoading: false,
      error: `Inappropriate permission state to ask permission, in state: ${Notification.permission}`,
    });
    return;
  }
  setNotificationPermissionState((curr) => ({ ...curr, isLoading: true }));

  try {
    const permissionResult = await Notification.requestPermission();
    if (permissionResult !== "granted") {
      setNotificationPermissionState({
        state: permissionResult as NotificationPermissionState,
        isLoading: false,
        error: null,
      });
      return;
    }
    setNotificationPermissionState((curr) => ({
      ...curr,
      state: "granted",
    }));

    await handleSubscribeToPush(updateBrowserSubscription, setPushSubscription);
    setNotificationPermissionState((curr) => ({ ...curr, isLoading: false }));
  } catch (err) {
    setNotificationPermissionState((curr) => ({
      ...curr,
      isLoading: false,
      error: `Browser notification - set the initial state failed: ${getErrorMessage(
        err,
      )}`,
    }));
    return;
  }
}

async function resubscribeUser({
  setNotificationPermissionState,
  setPushSubscription,
  updateBrowserSubscription,
}: {
  setNotificationPermissionState: Dispatch<
    SetStateAction<NotificationPermissionType>
  >;
  setPushSubscription: Dispatch<SetStateAction<PushSubscriptionJSON | null>>;
  updateBrowserSubscription: ReturnType<typeof useUpdateBrowserSubscription>[0];
}) {
  const error = isValidSession();
  if (error) {
    setNotificationPermissionState({
      state: NOTIFICATION_PERMISSION_STATUS_UNSUPPORTED,
      isLoading: false,
      error,
    });
    return;
  }

  if (Notification.permission !== "granted") {
    setNotificationPermissionState({
      state: Notification.permission as NotificationPermissionState,
      isLoading: false,
      error: null,
    });
    return;
  }

  try {
    const existingSubscription = await getSubscriptionInfoWithTimeout();
    const existingSubscriptionJSON = existingSubscription?.toJSON();
    if (existingSubscriptionJSON?.endpoint && existingSubscriptionJSON?.keys) {
      setPushSubscription(existingSubscriptionJSON);
      const id_token = localStorage.getItem("id_token");
      await updateBrowserSubscription({
        ...existingSubscriptionJSON,
        id_token,
      });
      return;
    }

    await handleSubscribeToPush(updateBrowserSubscription, setPushSubscription);
  } catch (err) {
    setNotificationPermissionState((curr) => ({
      ...curr,
      isLoading: false,
      error: `Browser notification - set the initial state failed: ${getErrorMessage(
        err,
      )}`,
    }));
    return;
  }
}

export function useAskNotificationPermission(): NotificationPermissionContextType {
  const account = useLoggedInAccount();
  const [updateBrowserSubscription] = useUpdateBrowserSubscription({
    id: account?.id ?? -1,
  });
  const [notificationPermissionState, setNotificationPermissionState] =
    useState<NotificationPermissionType>(() => {
      if (typeof Notification === "undefined") {
        return {
          ...defaultNotificationPermission,
          state: NOTIFICATION_PERMISSION_STATUS_UNSUPPORTED,
        };
      }
      return {
        ...defaultNotificationPermission,
        state: Notification.permission,
      };
    });
  const [pushSubscription, setPushSubscription] =
    useState<PushSubscriptionJSON | null>(null);

  const handleAskPermission = async () => {
    await askPermission({
      updateBrowserSubscription,
      setNotificationPermissionState,
      setPushSubscription,
    });
  };

  const handleResubscribeUser = async () =>
    await resubscribeUser({
      updateBrowserSubscription,
      setNotificationPermissionState,
      setPushSubscription,
    });

  return {
    askPermission: handleAskPermission,
    resubscribeUser: handleResubscribeUser,
    notificationPermissionState,
    pushSubscription,
  };
}
