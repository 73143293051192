import { useTranslations } from "@recare/translations";
import { Display } from "ds/materials/typography";
import styled from "styled-components";

const PageWrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default function InactiveProfilePage({
  careproviderName = "",
}: {
  careproviderName: string | undefined;
}) {
  const translations = useTranslations();
  return (
    <PageWrapper>
      <Display style={{ marginBottom: 0 }}>
        {`${careproviderName} ${translations.careproviderApp.inactiveProfile.titleTop}`}
      </Display>
      <Display style={{ marginTop: 0 }}>
        {translations.careproviderApp.inactiveProfile.titleBottom}
      </Display>
    </PageWrapper>
  );
}
